@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
// @import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css");

// If you want to override variables do it here
@import "variables";

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
// @import "@coreui/coreui/scss/coreui";

@import "semantic-ui-css/semantic.min.css";

// // Import Chart.js custom tooltips styles
// @import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";
@import "icons";
@import "uikit";
@import "login";

//TypoReWork
@import "typo";

//Pramod
@import "pramodui";

//Tharindu
@import "tharinduui";

body {
  background-color: $white !important;
  font-family: 'Poppins', sans-serif !important;
  padding: 105px 0 300px 0;

  @media (max-width: 1440px) {
    padding-top: 80px;
  }

  @media (max-width: 1024px) {
    padding-bottom: 250px;
  }

  @media (max-width: 1150px) {
    padding-top: 55px;
  }

  @media (max-width: 640px) {
    padding-bottom: 315px;
  }

  @media (max-width: 400px) {
    padding-bottom: 440px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Montserrat', sans-serif;
}

// to be removed - temporary iFrame issue fix
body>iframe[style*='2147483647'] {
  display: none;
}

.carosel-nav {
  .bannerimg {
    width: 100% !important;
    height: 80px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    a {
      height: 80px !important;
      width: 100% !important;

      img {
        width: 100% !important;
        height: 100% !important;
        // max-height: fit-content !important;
        object-fit: cover;
      }

      @media (max-width: 430px) {
        //visibility: hidden;
        //visibility: hidden;
      }
    }
  }
}

.bannerimgM {
  visibility: hidden;

  @media (max-width: 430px) {
    visibility: visible;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.Nav {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $white;
  z-index: 1000;
  padding: 0;

  .NavContainer {
    width: 100%;
    min-height: 105px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;
    padding: 0 50px;
    background-color: $white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);

    @media (max-width: 1440px) {
      min-height: 80px;
    }

    @media (max-width: 1280px) {
      padding: 0 20px;
    }

    @media (max-width: 1150px) {
      min-height: 55px;
    }

    @media (max-width: 1024px) {
      padding: 0 16px;
    }
  }

  .NavLeft {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .NavBrnd {
      width: 215px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 1440px) {
        width: 130px;
        margin-right: 20px;
      }

      @media (max-width: 1150px) {
        display: none;
      }

      img {
        width: 100%;
      }
    }

    .NavBrndMobile {
      width: 20px;
      height: auto;
      display: none;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 1150px) {
        display: flex;
      }

      img {
        width: 100%;
      }
    }

    .NavLinks {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0 0 0 0;
      padding: 0;
      list-style: none;

      @media (max-width: 1024px) {
        display: none;
      }

      .NavItem {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
        margin: 0 0 0 40px;

        @media (max-width: 1440px) {
          margin: 0 0 0 20px;
        }

        a {
          width: auto;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: $siteblack;
          font-size: 18px;
          font-weight: 600;
          text-decoration: none;

          @media (max-width: 1600px) {
            font-size: 14px;
          }

          &:hover {
            color: $blue;
            text-decoration: none;
          }
        }

        .activ {
          color: $blue;
        }
      }
    }

    .MobileMenuOpne {
      width: 30px;
      height: 30px;
      display: none;
      align-items: center;
      justify-content: flex-start;
      margin-right: 0x;

      @media (max-width: 1024px) {
        display: flex;
      }

      i {
        font-size: 20px;
        color: $siteblack;
      }
    }
  }

  .NavRight {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 1024px) {
      display: none;
    }

    a {
      margin: 0 0 0 30px;

      @media (max-width: 1440px) {
        margin: 0 0 0 20px;
      }
    }
  }

  .NavRightMobile {
    width: auto;
    display: none;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 1024px) {
      display: flex;
    }

    a {
      margin: 0 0 0 30px;

      @media (max-width: 1440px) {
        margin: 0 0 0 20px;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }

      i {
        font-size: 24px;
        color: $darkblue;
      }
    }
  }

  .MobileMenuContainer {
    width: 300px;
    height: 100%;
    display: none;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #fff;
    position: fixed;
    left: -3000px;
    top: 0;
    z-index: 1;
    box-shadow: 10px 4px 10px rgb(0 0 0 / 5%);
    border-right: 1px solid #ecedf2;
    padding: 80px 25px 25px 16px;

    @media (max-width: 1024px) {
      display: flex;
    }

    .MobileMenuContent {
      width: 100%;
      height: auto;
      max-height: 100%;
      overflow-y: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      .MenuSection,
      .MenuSection2 {
        width: 100%;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        list-style: none;
        margin: 0 0 50px 0;
        padding: 0;

        li {
          width: 100%;
          margin-bottom: 20px;

          a {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: $siteblack;
            font-size: 16px;
            font-weight: 600;
            text-decoration: none;

            &:hover {
              color: $blue;
              text-decoration: none;
            }

            i {
              font-size: 20px;
              margin-right: 15px;
            }
          }
        }
      }

      .MenuSection2 {
        li {
          a {
            i {
              font-size: 24px;
            }
          }
        }
      }

      .btn-group {
        display: none;
      }
    }
  }

  .MMCOpen {
    left: 0;
    transition: 0.2s left;
  }
}

.MobileDropMenuOpen {
  display: none;

  @media (max-width: 1024) {
    display: flex;
  }
}

.NavRightLinks {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-right: 10px;
}

.NavRightList {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin: 0 0 0 0;
  padding: 0;

  @media (max-width: 1440px) {
    margin: 0 0 0 0;
  }

  @media (max-width: 1024px) {
    display: none;
  }

  li {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
}

.NavRightList {
  .NavDropDown {
    @media (max-width: 1024px) {
      display: none;
    }

    .btn-group {
      position: relative;

      button {
        width: 50px;
        height: 50px;
        border: 0;
        padding: 0;
        transition: none;
        background: #f5f3f3;
        border: 1px solid #edeaea;
        border-radius: 50px !important;
        position: relative;

        @media (max-width: 1440px) {
          width: 40px;
          height: 40px;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &:active {
          outline: none;
          box-shadow: none;
        }

        .avatar {
          width: 50px !important;
          height: 50px;
          position: relative;
          overflow: hidden;

          @media (max-width: 1440px) {
            width: 40px !important;
            height: 40px;
          }

          .avatar-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50px;
          }
        }
      }

      .dropdown-menu {
        width: 250px;
        min-height: 300px;
        left: unset !important;
        right: 0 !important;
        top: 60px !important;
        background: #F8F9FF;
        border: 1px solid #ecedf2;
        box-shadow: 0px 0px 29px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        transform: none !important;
      }
    }
  }
}

.NavDropContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  .TopContent {
    width: 100%;
    height: 250px;
    background-color: #fff;
    border-bottom: 1px solid #ecedf2;
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;

    .ProPicture {
      width: 120px;
      height: 120px;
      background: #ffffff;
      border: 1px solid #e6e6e7;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      overflow: hidden;
      border-radius: 120px;
      margin-bottom: 10px;

      img {
        width: 100%;
        border-radius: 120px;
        object-fit: cover;
        height: 100%;
      }
    }

    .ProfPicture {
      width: 120px;
      height: 120px;
      background: #ffffff;
      border: 1px solid #e6e6e7;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      overflow: hidden;
      border-radius: 120px;
      margin-bottom: 10px;

      .ProPicContent {
        width: 20px;
        height: 20px;

        .ProPic {
          width: 20px;
          height: 20px;
          border-radius: 20px;

          .image {
            width: 100%;
            border-radius: 80px;
            object-fit: cover;
            height: 100%;
          }
        }
      }

      // img {
      //   width: 100%;
      //   border-radius: 120px;
      //   object-fit: cover;
      //   height: 100%;
      // }
    }

    .ProName {
      width: auto;
      font-weight: 700;
      font-size: 20px;
      color: $siteblack;
      text-align: center;
    }

    .ProEmail {
      width: auto;
      font-weight: 700;
      font-size: 13px;
      color: $textgray;
    }
  }

  .LinksContent {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 25px;
    margin: 0;
    list-style: none;

    li {
      width: 100%;
      height: auto;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        width: 100%;
        height: auto;
        font-size: 18px;
        font-weight: 400;
        color: $textgray;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        cursor: pointer;

        i {
          font-size: 24px;
          width: 24px;
          margin-right: 15px;
        }

        &:hover {
          color: $blue;
        }
      }
    }
  }
}

.Footer {
  width: 100%;
  height: auto;
  // min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  border: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: $darkblue;
  z-index: 998;
  padding: 30px 50px;

  @media (max-width: 1024px) {
    padding: 30px 20px;
  }

  @media (max-width: 1024px) {
    min-height: 250px;
  }

  .FooterTop {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 640px) {
      position: relative;
      flex-direction: column;
    }

    .FooterLeft {
      @media (max-width: 1368px) {
        align-items: flex-start;
      }

      @media (max-width: 640px) {
        margin-bottom: 20px;
        width: 100%;
        justify-content: space-between;
      }

      @media (max-width: 640px) {
        flex-direction: column;
        margin-bottom: 1.5rem;
      }

      .NavLinks {
        width: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin: 0 0 0 0;
        padding: 0 100px 0 0;
        list-style: none;

        @media (max-width: 1280px) {
          padding: 0 50px 0 0;
        }

        @media (max-width: 1024px) {
          padding: 0 30px 0 0;
        }

        @media (max-width: 480px) {
          padding: 0 0 0 0;
        }

        @media (max-width: 640px) {
          width: 100%;
          flex-direction: unset;
          flex-wrap: wrap;
          margin-bottom: 15px;
        }

        .NavItem {
          width: auto;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0;
          margin: 0 0 10px 0;

          @media (max-width: 640px) {
            width: 50%;
            margin: 0 0 5px 0;

            &:first-child {
              width: 100%;

              h3 {
                margin-bottom: 5px;
              }
            }
          }

          h3 {
            width: auto;
            font-size: 18px;
            color: #fff;
            font-weight: 700;
            padding: 0;
            margin: 0 0 10px 0;

            @media (max-width: 1368px) {
              font-size: 16px;
            }

            @media (max-width: 920px) {
              font-size: 14px;
            }
          }

          a {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: $siteblack;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            transition: 0.2s;

            @media (max-width: 1368px) {
              font-size: 14px;
            }

            @media (max-width: 920px) {
              font-size: 12px;
            }

            &:hover {
              color: #bdbdbd;
              text-decoration: none;
            }
          }
        }
      }
    }

    .FooterRight {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 1368px) {
        align-items: flex-start;
      }

      @media (max-width: 640px) {
        width: 100%;
        margin-bottom: 30px;
      }

      @media (max-width: 440px) {
        width: 100%;
      }

      .NavLinks {
        width: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        list-style: none;

        @media (max-width: 640px) {
          width: 100%;
        }

        @media (max-width: 640px) {
          width: 100%;
          justify-content: space-between;
          flex-direction: unset;
          flex-wrap: wrap;
        }

        @media (max-width: 400px) {
          flex-wrap: wrap;
        }

        .NavItem {
          width: auto;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0;
          margin: 0 0 10px 0;

          @media (max-width: 640px) {
            &:first-child {
              width: 100%;

              h3 {
                width: auto;
                font-size: 14px;
                color: #fff;
                font-weight: 700;
                padding: 0;
                margin: 0 0 5px 0;
                display: flex;
              }
            }
          }

          @media (max-width: 400px) {
            width: 50%;
          }

          .NavBrnd {
            width: 190px;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;

            @media (max-width: 1368px) {
              width: 150px;
            }

            @media (max-width: 920px) {
              width: 120px;
            }

            @media (max-width: 640px) {
              display: none;
            }

            img {
              width: 100%;
            }
          }

          h3 {
            display: none;
          }

          a {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: $siteblack;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            transition: 0.2s;

            @media (max-width: 1368px) {
              font-size: 14px;
            }

            @media (max-width: 920px) {
              font-size: 12px;
            }

            &:hover {
              color: #bdbdbd;
              text-decoration: none;
            }

            i {
              width: 30px;
              font-size: 16px;
              color: $siteblack;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              @media (max-width: 1368px) {
                font-size: 14px;
                width: 20px;
              }
            }
          }
        }
      }
    }
  }

  .FooterBottom {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // margin: 30px 0 0 0;

    @media (max-width: 1368px) {
      margin: 15px 0 0 0;
    }

    p {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $siteblack;
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      padding: 0;

      @media (max-width: 1368px) {
        font-size: 10px;
      }
    }
  }
}

.IndexsBannerSection {
  padding: 0 90px;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 150px);

  @media (min-width: 1921px) {
    min-height: auto;
    padding: 150px 50px;
  }

  @media (min-height: 1080px) {
    min-height: auto;
    padding: 100px 50px;
  }

  @media (max-width: 1680px) {
    min-height: auto;
    padding: 100px 50px;
  }

  @media (max-width: 1600px) {
    padding: 0 50px;
  }

  @media (max-width: 1280px) {
    min-height: auto;
    padding: 100px 50px;
  }

  @media (max-width: 1024px) {
    padding: 50px 16px;
  }

  @media (max-width: 500px) {
    padding: 0 16px;
    min-height: calc(100vh - 55px);
  }

  .IBSContainer {
    width: 100%;
    max-width: 1740px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 1024px) {
      flex-direction: column;
      justify-content: center;
    }

    .IBSLeft {
      width: calc(100% - 800px);
      max-width: 800px;
      height: auto;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;

      .heading-1 {
        margin-bottom: calc(16px + 14 * (100vw - 300px) / 1300);
      }

      @media (max-width: 1600px) {
        width: calc(100% - 700px);
      }

      @media (max-width: 1440px) {
        width: 600px;
      }

      @media (max-width: 1280px) {
        width: 535px;
      }

      @media (max-width: 1200px) {
        width: 575px;
      }

      @media (max-width: 1024px) {
        order: 2;
        width: auto;
        max-width: 100%;
        align-items: center;
        text-align: center;
      }

      @media (max-width: 650px) {
        text-align: center;
      }

      h3 {
        width: 100%;
        padding: 0;
        height: auto;
        color: $siteblack;
        margin: 0 0 30px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        font-size: 54px;
        font-weight: 800;

        @media (max-width: 1440px) {
          font-size: 54px;
        }

        @media (max-width: 1280px) {
          font-size: 48px;
        }

        @media (max-width: 1200px) {
          font-size: 40px;
          margin: 0 0 20px 0;
        }

        @media (max-width: 1024px) {
          justify-content: center;
          text-align: center;
        }

        @media (max-width: 500px) {
          font-size: 24px;
          margin-bottom: 15px;
        }

        @media (min-width: 370px) and (max-width: 415px) {
          font-size: 30px;
        }

        span {
          width: 100%;
        }
      }

      h5 {
        width: 100%;
        padding: 0;
        height: auto;
        color: $siteblack;
        margin: 0;
        color: $textgray;

        @media (max-width: 1440px) {
          font-size: 18px;
        }

        @media (max-width: 1200px) {
          font-size: 16px;
        }

        @media (max-width: 1024px) {
          width: 100%;
          text-align: center;
          display: flex;
          justify-content: center;
        }

        @media (max-width: 500px) {
          font-size: 12px;
        }
      }

      .FormCard {
        width: 100%;
        height: auto;
        min-height: 87px;
        padding: 17px;
        background-color: $white;
        box-shadow: 10px 4px 10px rgba(0, 0, 0, 0.05);
        border: 1px solid #ecedf2;
        border-radius: 5px;
        margin: 30px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 1440px) {
          min-height: 70px;
          padding: 10px;
        }

        @media (max-width: 1200px) {
          margin: 20px 0 0 0;
        }

        @media (max-width: 531px) {
          box-shadow: none;
          border: 0;
          background-color: transparent;
          flex-direction: column;
        }

        @media (max-width: 500px) {
          margin: 10px 0 0 0;
        }

        .InputContent {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          @media (max-width: 531px) {
            flex-direction: column;
            width: 100%;
          }

          .form-group {
            flex: 1;

            @media (max-width: 531px) {
              flex: unset;
              width: 100%;
            }

            .BorderLeft {
              // border-left: #dedfe3 solid 1px;
              padding-left: 30px;

              @media (max-width: 1680px) {
                padding-left: 20px;
              }

              @media (max-width: 1280px) {
                padding-left: 10px;
              }

              @media (max-width: 1200px) {
                width: 150px;
                flex: unset;
                padding-left: 15px;
              }

              @media (max-width: 1024px) {
                flex: 1;
                padding-left: 15px;
              }

              @media (max-width: 531px) {
                background-color: $white;
                box-shadow: 10px 4px 10px rgba(0, 0, 0, 0.05);
                border: 1px solid #ecedf2;
                padding: 15px;
                flex: unset;
                width: 100%;
                border-radius: 3px;
              }
            }
          }

          input {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            height: 50px;
            font-size: 18px;
            padding-right: 30px;
            padding-left: 0;

            @media (max-width: 1680px) {
              padding-right: 20px;
              font-size: 16px;
            }

            @media (max-width: 1440px) {
              font-size: 14px;
            }

            @media (max-width: 1280px) {
              padding-right: 10px;
            }

            @media (max-width: 1200px) {
              padding-left: 15px;
              padding-right: 15px;
              width: 205px;
              flex: unset;
            }

            @media (max-width: 1024px) {
              flex: 1;
            }

            @media (max-width: 531px) {
              background-color: $white;
              box-shadow: 10px 4px 10px rgba(0, 0, 0, 0.05);
              border: 1px solid #ecedf2;
              padding: 15px;
              flex: unset;
              width: 100%;
              border-radius: 3px;
              margin-bottom: 15px;
            }
          }
        }

        button {
          margin: 0 0 0 15px;

          @media (max-width: 531px) {
            width: 100%;
            box-shadow: 10px 4px 10px rgba(0, 0, 0, 0.05);
            min-height: 50px;
            margin: 0;
            border-radius: 3px;
          }
        }
      }
    }

    .IBSRight {
      width: 800px;
      max-width: 800px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 0 0 60px;

      @media (max-width: 1600px) {
        width: 700px;
        max-width: 700px;
      }

      @media (max-width: 1440px) {
        flex: 1;
      }

      @media (max-width: 1280px) {
        flex: 1;
      }

      @media (max-width: 1024px) {
        width: 100%;
        padding: 0 0 0 0;
      }

      @media (max-width: 500px) {
        margin-bottom: 20px;
      }

      img {
        width: 100%;

        @media (max-width: 1024px) {
          width: 80%;
        }

        @media (min-width: 370px) and (max-width: 415px) {
          width: 90%;
        }
      }

      .carousel-root {
        width: 100%;

        .carousel {
          position: relative;
          padding-bottom: 0;

          .control-dots {
            display: none;
          }

          .control-arrow {
            display: none;
          }
        }
      }
    }
  }
}

.RecJobContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0;

  @media (max-width: 700px) {
    padding: 30px 0;
  }

  .TopContent {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;

    .Hedding {
      width: auto;
      height: auto;
      font-size: 36px;
      font-weight: 700;
      color: $siteblack;
      margin: 0;
      padding: 0;
    }
  }

  .MiddleContent {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 700px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .IconContent {
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .newICo {
        width: 150px !important;
        height: 150px !important;
      }

      .Icon {
        width: 150px;
        height: auto;
        margin-right: 30px;

        img {
          width: 100%;
          border-radius: 6px;
        }

        @media (max-width: 1440px) {
          width: 120px;
        }

        @media (max-width: 1368px) {
          width: 100px;
        }

        @media (max-width: 700px) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      .Title {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 26px;
        font-weight: 400;
        color: $textgray;
        margin: 0;
        padding: 0;

        @media (max-width: 1440px) {
          font-size: 24px;
        }

        @media (max-width: 1368px) {
          font-size: 20px;
        }

        span {
          font-size: 20px;
          margin: 10px 0 0 0;
          padding: 0;

          @media (max-width: 1440px) {
            font-size: 18px;
          }

          @media (max-width: 1368px) {
            font-size: 16px;
          }
        }
      }
    }

    .TextContent {
      flex: 1;
      padding: 0 0 0 170px;
      margin: 0;
      font-size: 20px;
      font-weight: 400;
      color: $textgray;
      text-align: left;

      @media (max-width: 1440px) {
        font-size: 18px;
      }

      @media (max-width: 1368px) {
        font-size: 16px;
      }

      @media (max-width: 1280px) {
        padding: 0 0 0 100px;
      }

      @media (max-width: 700px) {
        padding: 0 0 0 0;
        width: 100%;
        font-size: 14px;
      }
    }
  }

  .BottomContent {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 40px;

    @media (max-width: 700px) {
      margin-top: 20px;
    }
  }
}

.JobRowContainer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  .JobRow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -40px;
    margin-left: -40px;

    @media (max-width: 1440px) {
      margin-right: -25px;
      margin-left: -25px;
    }

    @media (max-width: 1024px) {
      margin-right: -10px;
      margin-left: -10px;
    }

    .JobCol50 {
      flex: 50%;
      max-width: 50%;
      padding: 0 40px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 30px;

      @media (max-width: 1440px) {
        padding: 0 25px;
      }

      @media (max-width: 1024px) {
        padding: 0 10px;
        margin-bottom: 20px;
      }

      @media (max-width: 700px) {
        flex: 100%;
        max-width: 100%;
      }
    }
  }
}

.JobCard {
  width: 100%;
  height: auto;
  min-height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: $bluegray;
  border: 1px solid #ecedf2;
  border-radius: 5px;
  box-shadow: 10px 10px 9px rgba(0, 0, 0, 0.03);
  padding: 20px;
  margin: 0 0 0 0;
  position: relative;
}

.JobCardContent {
  width: 100%;
  display: flex;
  //   align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-self: flex-start;
  }

  .Left {
    flex: 1;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media (max-width: 400px) {
      flex-direction: column;
      align-self: flex-start;
      flex: unset;
      width: 100%;
    }

    .IconContent {
      width: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      @media (max-width: 400px) {
        margin-bottom: 15px;
        width: 100%;
      }

      .Icon {
        width: 90px;
        height: auto;
        display: flex;
        align-items: flex-start;

        img {
          width: 100%;
        }

        @media (max-width: 1440px) {
          width: 80px;
        }

        @media (max-width: 1368px) {
          width: 70px;
        }
      }

      h3 {
        width: auto;
        height: auto;
        min-height: 70px;
        font-size: 18px;
        font-weight: 500;
        color: $siteblack;
        text-align: left;
        white-space: pre-wrap;
        word-break: break-all;
        display: none;
        align-items: center;
        margin-left: 20px;

        @media (max-width: 400px) {
          display: flex;
        }
      }
    }

    .Icon {
      width: 90px;
      height: auto;
      display: flex;
      align-items: flex-start;

      img {
        width: 100%;
      }

      @media (max-width: 1440px) {
        width: 80px;
      }

      @media (max-width: 1368px) {
        width: 70px;
      }
    }

    .TextContent {
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding: 0 30px;

      @media (max-width: 1200px) {
        padding: 0 0 0 20px;
      }

      @media (max-width: 400px) {
        padding: 0 0 0 0px;
      }

      h3 {
        width: 100%;
        height: auto;
        font-size: 22px;
        font-weight: 500;
        color: $siteblack;
        text-align: left;
        white-space: pre-wrap;
        word-break: break-all;

        @media (max-width: 1368px) {
          font-size: 20px;
        }

        @media (max-width: 440px) {
          font-size: 18px;
          margin-bottom: 10px;
        }

        @media (max-width: 400px) {
          display: none;
        }
      }

      .TextPadding {
        @media (max-width: 1200px) {
          padding-right: 40px;
        }
      }

      h4 {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 20px;
        font-weight: 500;
        color: $siteblack;
        padding: 0;
        margin: 0 0 15px 0;

        @media (max-width: 1440px) {
          font-size: 18px;
        }

        @media (max-width: 1368px) {
          font-size: 16px;
        }

        @media (max-width: 1280px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }

        @media (max-width: 440px) {
          margin-bottom: 15px;
        }

        span {
          color: $darkblue;
          font-size: 14px;
          padding: 0;
          margin: 0 0 0 40px;
          font-weight: 800;

          @media (max-width: 1280px) {
            width: 100%;
            margin: 10px 0 0 0;
            text-align: left;
          }

          @media (max-width: 440px) {
            margin: 0 0 0 0;
          }
        }
      }

      p {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        font-weight: 500;
        color: $textgray;
        padding: 0;
        margin: 0 0 15px 0;

        @media (max-width: 1440px) {
          font-size: 14px;
        }

        @media (max-width: 1440px) {
          margin: 0 0 10px 0;
        }

        @media (max-width: 440px) {
          margin: 0 0 5px 0;
        }

        i {
          margin-right: 10px;
        }
      }

      .TextContentInline {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;

        @media (max-width: 1200px) {
          margin: 0 0 15px 0;
        }

        @media (max-width: 990px) {
          flex-direction: column;
        }

        p {
          width: auto;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 16px;
          font-weight: 500;
          color: $textgray;
          padding: 0;
          margin: 0 20px 0 0;

          @media (max-width: 1280px) {
            font-size: 14px;
          }

          @media (max-width: 990px) {
            margin: 0 0 10px 0;
          }

          i {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .Right {
    width: auto;
    min-width: 120px;
    height: auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;
    position: relative;

    @media (max-width: 1200px) {
      justify-content: space-between;
      align-items: center;
    }

    @media (max-width: 440px) {
      margin-top: 20px;
    }

    span {
      font-size: 16px;
      font-weight: 500;
      color: $textgray;
      align-self: flex-start;

      @media (max-width: 1440px) {
        font-size: 14px;
      }

      @media (max-width: 1368px) {
        font-size: 12px;
      }

      @media (max-width: 1200px) {
        align-self: center;
        margin-left: 90px;
      }

      @media (max-width: 400px) {
        margin-left: 0;
      }
    }

    i {
      font-size: 30px;
      font-weight: 500;
      color: $textgray;
      align-self: flex-start;
      cursor: pointer;

      @media (max-width: 1200px) {
        margin-left: 0;
      }
    }

    button,
    a {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $darkblue;
      border-radius: 5px;
      border: 0;
      color: $white;
      font-size: 16px;
      align-self: flex-end;
      position: absolute;
      bottom: 0;
      right: 0;
      text-decoration: none;
      opacity: 0.9;

      &:hover {
        text-decoration: none;
        opacity: 1;
      }

      @media (max-width: 1368px) {
        font-size: 14px;
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
        height: 36px;
        border-radius: 3px;
      }

      @media (max-width: 1200px) {
        position: relative;
      }

      @media (max-width: 440px) {
        padding-left: 10px;
        padding-right: 10px;
        height: 30px;
        border-radius: 2px;
        font-size: 12px;
      }
    }
  }

  .FlexEnd {
    @media (max-width: 1200px) {
      justify-content: flex-end;
      position: unset;
    }

    i {
      @media (max-width: 1200px) {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
  }
}

.CampaignContainer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  padding: 10px;

  .CapRow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -60px;
    margin-left: -60px;

    @media (max-width: 1600px) {
      margin-right: -25px;
      margin-left: -25px;
    }

    @media (max-width: 1024px) {
      margin-right: -10px;
      margin-left: -10px;
    }

    .CapCol33 {
      flex: 33.33%;
      max-width: 33.33%;
      padding: 0 60px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 30px;

      @media (max-width: 1600px) {
        padding: 25px;
      }

      @media (max-width: 1024px) {
        padding: 10px;
      }
    }
  }
}

.CampaignContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  .CapImg {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    overflow: hidden;
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }

    @media (max-width: 1600px) {
      min-width: 100%;
    }

    img {
      width: 100%;
    }
  }

  .CapDetails {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 20px 0 0 0;

    .CapAbout {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 0 0 0;

      .Campaigner {
        flex: 1;
        font-weight: 500;
        font-size: 18px;
        color: $textgray;
        margin: 0;
        padding: 0 20px 0 0;
        text-align: left;

        @media (max-width: 1368px) {
          font-size: 16px;
        }
      }

      .CapDate {
        width: auto;
        font-weight: 500;
        font-size: 14px;
        color: $textgray;
        display: flex;
        align-items: center;

        @media (max-width: 1368px) {
          font-size: 14px;
        }

        i {
          margin: 0 10px 0 0;
          font-size: 18px;
        }
      }
    }


    .CapTitle {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 24px;
      margin: 20px 0 0 0;
      padding: 0;
      color: $siteblack;
      text-align: left;

      .no-style {
        text-decoration: none !important;
        color: black;
      }

      @media (max-width: 1440px) {
        font-size: 20px;
      }

      @media (max-width: 1368px) {
        font-size: 18px;
        margin-top: 15px;
      }
    }
  }
}

.CareerContainer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;

  @media (max-width: 1024px) {
    margin-top: 10px;
  }

  @media (max-width: 440px) {
    overflow-x: auto;
  }

  .CreRow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -45px;
    margin-left: -45px;

    @media (max-width: 1600px) {
      margin-right: -25px;
      margin-left: -25px;
    }

    @media (max-width: 1024px) {
      margin-right: -10px;
      margin-left: -10px;
    }

    @media (max-width: 440px) {
      flex-wrap: nowrap;
    }

    .CreCol25 {
      flex: 25%;
      max-width: 25%;
      padding: 0 45px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 30px;

      @media (max-width: 1600px) {
        padding: 0 25px;
      }

      @media (max-width: 1024px) {
        padding: 0 10px;
      }

      @media (max-width: 768px) {
        flex: 50%;
        max-width: 50%;
        margin-bottom: 60px;
      }

      @media (max-width: 440px) {
        flex: 100vw;
        max-width: 100vw;
        margin-bottom: 60px;
      }
    }
  }
}

.CareerCad {
  width: 100%;
  height: auto;
  min-height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border: 1px solid #ecedf2;
  background-color: #F8F9FF;
  box-shadow: 10px 10px 9px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 25px;

  .body-1 {
    text-align: center;
  }

  @media (max-width: 1368px) {
    min-height: 230px;
  }

  @media (max-width: 440px) {
    min-width: 310px;
  }

  img {
    margin-bottom: 1rem;
  }

  .Icon {
    width: 70px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $darkblue;
    font-size: 50px;
    margin-bottom: 15px;

    @media (max-width: 1440px) {
      width: 60px;
      font-size: 46px;
    }

    @media (max-width: 1368px) {
      width: 50px;
      font-size: 44px;
    }
  }

  .Text {
    width: 100%;
    font-weight: normal;
    font-size: 20px;
    color: $textgray;
    margin: 0 0 20px 0;
    padding: 0;
    text-align: center;

    @media (max-width: 1440px) {
      font-size: 18px;
    }

    @media (max-width: 1368px) {
      font-size: 16px;
    }
  }

  .Link {
    width: 86px;
    height: 86px;
    background: $blue;
    border: 1px solid #ecedf2;
    box-shadow: 10px 10px 9px rgba(0, 0, 0, 0.03);
    border-radius: 26px;
    transform: rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    opacity: 0.9;
    text-decoration: none;
    font-size: 24px;
    position: absolute;
    bottom: -43px;

    &:hover {
      opacity: 1;
      text-decoration: none;
    }

    @media (max-width: 1440px) {
      width: 70px;
      height: 70px;
      bottom: -35px;
      border-radius: 18px;
    }

    @media (max-width: 1368px) {
      width: 60px;
      height: 60px;
      bottom: -30px;
      border-radius: 16px;
    }

    i {
      transform: rotate(-45deg) !important;
    }
  }
}

.box {
  width: 223px;
  height: 100px;
  border: dashed 2px #000;
  border-color: #000 transparent transparent transparent;
  border-radius: 58%/110px 100px 0 0;
  position: relative;

  &:after {
    content: " ";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid green;
    position: absolute;
    top: 21px;
    right: 21px;
    transform: rotate(45deg);
  }
}

.WorksContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 100px;
  flex-wrap: wrap;

  @media (max-width: 540px) {
    flex-direction: column;
    margin-top: 100px;
  }

  .WorkCard1 {
    width: 33.33%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 540px) {
      margin-bottom: 200px;
    }
  }

  .WorkCard2 {
    width: 33.33%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 540px) {
      margin-bottom: 200px;
    }
  }

  .WorkCard3 {
    width: 33.33%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 540px) {
      margin-bottom: 200px;
    }
  }

  .WorkCard4 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    font-size: 20px;
    font-weight: 400;
    color: $textgray;
    margin-top: 150px;

    @media (max-width: 1440px) {
      font-size: 18px;
    }

    @media (max-width: 540px) {
      margin-top: 0;
    }
  }
}

.WorksCard {
  width: 230px;
  height: 230px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  box-sizing: border-box;
  box-shadow: 10px 10px 9px rgba(0, 0, 0, 0.01);
  border-radius: 80px;
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 1440px) {
    width: 180px;
    height: 180px;
    border-radius: 60px;
  }

  @media (max-width: 1024px) {
    width: 150px;
    height: 150px;
    border-radius: 40px;
  }

  @media (max-width: 700px) {
    width: 100px;
    height: 100px;
    border-radius: 30px;
  }

  img {
    width: 70px;
    transform: rotate(-45deg);

    @media (max-width: 1440px) {
      width: 50px;
    }

    @media (max-width: 1440px) {
      width: 40px;
    }

    @media (max-width: 700px) {
      width: 30px;
    }
  }
}

.Number {
  width: auto;
  display: flex;
  align-items: center;
  font-size: 60px;
  color: $textgray;
  position: absolute;
  top: -55px;
  z-index: 2;

  @media (max-width: 1440px) {
    font-size: 48px;
    top: -48px;
  }

  @media (max-width: 1024px) {
    font-size: 40px;
    top: -40px;
  }

  @media (max-width: 700px) {
    font-size: 30px;
    top: -30px;
  }
}

.Tag {
  width: auto;
  display: flex;
  align-items: center;
  font-size: calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300)));
  color: $textgray;
  position: absolute;
  bottom: 0;
  // left: 50%;
  z-index: 2;
  margin: 0;
  text-align: center;

  // @media (max-width:1440px) {
  //   font-size: 20px;
  // }

  @media (max-width: 1024px) {
    // font-size: 16px;
    left: auto;
    right: auto;
  }

  // @media (max-width:700px) {
  //   font-size: 14px;
  // }

  @media (max-width: 540px) {
    // width: 130px;
    justify-content: center;
    white-space: nowrap;
  }

  @media (max-width: 450px) {
    margin-top: 3rem !important;
    text-align: center;
  }
}

.LineArrowContent {
  width: auto;
  position: absolute;
  top: -40px;
  right: -100px;

  @media (max-width: 600px) {
    right: -60px;
  }

  @media (max-width: 540px) {
    transform: rotate(90deg);
    top: unset;
    right: -50px;
    bottom: -45px;
  }
}

.LineArrow {
  width: 223px;
  height: 100px;
  border: dashed 2px #15c006;
  border-color: #15c006 transparent transparent transparent;
  border-radius: 58%/110px 100px 0 0;
  position: relative;

  @media (max-width: 768px) {
    width: 180px;
  }

  @media (max-width: 600px) {
    width: 120px;
  }

  &:after {
    content: " ";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #15c006;
    position: absolute;
    top: 21px;
    right: 21px;
    transform: rotate(45deg);

    @media (max-width: 768px) {
      top: 18px;
    }

    @media (max-width: 600px) {
      top: 11px;
      right: 16px;
    }
  }
}

.LineArrowContent2 {
  width: auto;
  position: absolute;
  bottom: -40px;
  right: -100px;

  @media (max-width: 600px) {
    right: -60px;
  }

  @media (max-width: 540px) {
    transform: rotate(90deg);
    top: unset;
    right: unset;
    left: -50px;
    bottom: -45px;
  }
}

.LineArrow2 {
  width: 223px;
  height: 100px;
  border: dashed 2px #15c006;
  border-color: #15c006 transparent transparent transparent;
  border-radius: 58%/110px 100px 0 0;
  position: relative;
  transform: rotate(180deg);

  @media (max-width: 768px) {
    width: 180px;
  }

  @media (max-width: 600px) {
    width: 120px;
  }

  @media (max-width: 540px) {
    width: 120px;
    height: 100px;
    border: dashed 2px #15c006;
    border-color: #15c006 transparent transparent transparent;
    border-radius: 58%/110px 100px 0 0;
    position: relative;
  }

  &:after {
    content: " ";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #15c006;
    position: absolute;
    top: 22px;
    left: 21px;
    transform: rotate(145deg);

    @media (max-width: 768px) {
      top: 18px;
    }

    @media (max-width: 600px) {
      top: 11px;
      left: 16px;
    }

    @media (max-width: 540px) {
      content: " ";
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid #15c006;
      position: absolute;
      top: 11px;
      right: 16px;
      transform: rotate(135deg);
    }
  }
}

.FeatureContainer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;

  @media (max-width: 1024px) {
    margin-top: 0;
  }

  @media (max-width: 440px) {
    overflow-x: auto;
  }

  .FcRow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -45px;
    margin-left: -45px;

    @media (max-width: 1600px) {
      margin-right: -20px;
      margin-left: -20px;
    }

    @media (max-width: 1024px) {
      margin-right: -10px;
      margin-left: -10px;
    }

    @media (max-width: 440px) {
      flex-wrap: nowrap;
    }

    .FcCol25 {
      flex: 25%;
      max-width: 25%;
      padding: 0 30px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 30px;

      @media (max-width: 1600px) {
        padding: 0 20px;
      }

      @media (max-width: 1336px) {
        flex: 50%;
        max-width: 50%;
        margin-bottom: 50px;
        padding: 0 10px;
      }

      @media (max-width: 668px) {
        flex: 100%;
        max-width: 100%;
        margin-bottom: 50px;
      }

      @media (max-width: 440px) {
        flex: 100vw 1;
        max-width: 100vw;
        margin-bottom: 60px;
      }
    }
  }
}

.FeatureCad {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  border: 1px solid #ecedf2;
  box-shadow: 10px 10px 9px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 50px 30px;

  @media (max-width: 1440px) {
    padding: 50px 30px;
  }

  @media (max-width: 440px) {
    min-width: 310px;
  }

  img {
    margin-bottom: 1rem;
  }

  .Icon {
    width: 70px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blue;
    font-size: 50px;
    margin-bottom: 15px;

    @media (max-width: 1440px) {
      width: 60px;
      font-size: 46px;
    }

    @media (max-width: 1368px) {
      width: 50px;
      font-size: 44px;
    }
  }

  .Title {
    width: auto;
    font-weight: normal;
    font-size: 20px;
    color: $blue;
    margin: 0 0 20px 0;
    padding: 0;
    text-align: center;
    text-transform: capitalize;

    @media (max-width: 1440px) {
      font-size: 18px;
    }

    @media (max-width: 1368px) {
      font-size: 16px;
    }
  }

  .Text {
    width: auto;
    font-weight: normal;
    font-size: 16px;
    color: $textgray;
    margin: 0 0 20px 0;
    padding: 0;
    text-align: center;

    @media (max-width: 1368px) {
      font-size: 14px;
    }
  }

  .Link {
    width: 86px;
    height: 86px;
    background: #F8F9FF;
    border: 1px solid #ecedf2;
    box-shadow: 10px 10px 9px rgba(0, 0, 0, 0.03);
    border-radius: 26px;
    transform: rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $darkblue;
    opacity: 0.9;
    text-decoration: none;
    font-size: 24px;
    position: absolute;
    bottom: -43px;

    @media (max-width: 1440px) {
      width: 70px;
      height: 70px;
      bottom: -35px;
      border-radius: 18px;
    }

    @media (max-width: 1368px) {
      width: 60px;
      height: 60px;
      bottom: -30px;
      border-radius: 16px;
    }

    &:hover {
      opacity: 1;
      text-decoration: none;
    }

    i {
      transform: rotate(-45deg) !important;
    }
  }
}

.CustomersContainer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;

  .CusRow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -45px;
    margin-left: -45px;

    @media (max-width: 1600px) {
      margin-right: -25px;
      margin-left: -25px;
    }

    @media (max-width: 1024px) {
      margin-right: -10px;
      margin-left: -10px;
    }

    .CusCol33 {
      flex: 33.33%;
      max-width: 33.33%;
      padding: 0 45px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 30px;

      @media (max-width: 1600px) {
        padding: 0 25px;
      }

      @media (max-width: 1024px) {
        padding: 0 10px;
      }

      @media (max-width: 768px) {
        flex: 100%;
        max-width: 100%;
        margin-bottom: 4rem !important;
      }
    }
  }

  .CusRow2 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    .CusCol16 {
      flex: 16.66%;
      max-width: 16.66%;
      padding: 0 15px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
  }
}

.CustomersCad {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  border: 1px solid #ecedf2;
  box-shadow: 10px 10px 9px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 30px 30px 60px 30px;
  background-color: #F8F9FF;

  .Icon {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #cedaff;
    font-size: 50px;
    margin-bottom: 15px;
  }

  .Title {
    width: auto;
    font-weight: normal;
    font-size: 20px;
    color: $blue;
    margin: 0 0 20px 0;
    padding: 0;
    text-transform: capitalize;

    @media (max-width: 1368px) {
      font-size: 18px;
    }

    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }

  .Text {
    width: auto;
    font-weight: normal;
    font-size: 16px;
    color: $textgray;
    margin: 0 0 20px 0;
    padding: 0;
    text-align: left;

    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }

  .CustomersContent {
    width: 100%;
    position: absolute;
    bottom: -30px;
    padding: 0 30px;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .Name {
      width: auto;
      height: auto;
      font-size: 16px;
      color: $siteblack;
      padding: 0;
      margin: 20px 0 0 10px;
      display: flex;

      @media (max-width: 1368px) {
        font-size: 14px;
      }

      span {
        color: $textgray;
        font-size: 14px;
        margin-left: 15px;

        @media (max-width: 1368px) {
          font-size: 12px;
        }
      }
    }

    .Customers {
      width: 80px;
      height: 80px;
      background: #F8F9FF;
      border: 1px solid #ecedf2;
      box-shadow: 10px 10px 9px rgba(0, 0, 0, 0.03);
      border-radius: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-size: 24px;
      overflow: hidden;

      @media (max-width: 1368px) {
        width: 70px;
        height: 70px;
      }

      img {
        width: 100%;
        border-radius: 80px;
      }
    }
  }
}

.CustomersImg {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
}

.FindJobsSection {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 105px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;

  .first-bx {
    .selection {
      border-left: none !important;
    }
  }

  @media (max-width: 1280px) {
    min-height: calc(100vh - 80px);
  }

  @media (max-width: 1150px) {
    min-height: calc(100vh - 55px);
  }

  @media (max-width: 800px) {
    position: relative;
  }

  .FindeJobHeaderContainer {
    width: 100%;
    height: auto;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 50px;
    background: $blue;

    @media (max-width: 1440px) {
      min-height: 180px;
    }

    @media (max-width: 1024px) {
      padding: 0 20px;
    }

    .FindeJobHeaderContent {
      width: 100%;
      max-width: 1937px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

.FJFormCard,
.TPFormCard {
  width: 100%;
  height: auto;
  min-height: 87px;
  padding: 17px;
  background-color: #ffffff;
  box-shadow: 10px 4px 10px rgb(0 0 0 / 5%);
  border: 1px solid #ecedf2;
  border-radius: 5px;
  margin: 0 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1440px) {
    min-height: 70px;
    padding: 10px;
  }

  @media (max-width: 800px) {
    display: none;
  }

  .InputContent {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 30px;

    .FGroup {
      flex: 1;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .combobox {
        border: none !important;
        outline: none !important;
      }

      input {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        height: 53px;
        padding-left: 15px;
        padding-right: 15px;
        color: $textgray;
        font-size: 16px;

        @media (max-width: 1368px) {
          height: 40px;
          font-size: 14px;
        }
      }

      .form-control {
        &:focus {
          box-shadow: none;
        }
      }

      .selection {
        height: 53px;
        border: 0;
        display: flex;
        align-items: center;
        border-left: #dedfe3 solid 1px;
        border-radius: 0;

        @media (max-width: 1368px) {
          height: 40px;
        }

        input {
          padding-left: 15px;
          border-radius: 0;
          color: $inputgray;
        }

        .icon {
          height: 50px;
          display: flex;
          align-items: center;
        }

        .text {
          color: $inputgray;
          font-size: 16px;

          @media (max-width: 1368px) {
            font-size: 14px;
          }
        }
      }

      .ui.selection.active.dropdown:hover {
        border: #dedfe3 solid 1px;
        box-shadow: none;
        border-radius: 3px;
      }

      .ui.selection.active.dropdown {
        border: #dedfe3 solid 1px;
        box-shadow: none;
        max-width: 99.9%;
      }

      .ui.selection.active.dropdown,
      .menu,
      .ui.selection.active.dropdown:hover .menu {
        border-color: #dedfe3;
        box-shadow: none;
        // max-width: 99.9%;
      }

      .ui.selection.active.dropdown:hover .menu {
        border-color: #dedfe3;
        box-shadow: none;
        // max-width: 99.9%;
      }
    }

    // .BorderLeft {
    //   border-left: #dedfe3 solid 1px;
    // }
  }
}

.ui.dropdown .menu {
  border: none !important;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background-color: #FFF;
}

.FindeJobBodyContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 60px 50px;

  @media (max-width: 1600px) {
    padding: 50px 50px;
  }

  @media (max-width: 1024px) {
    padding: 30px 20px;
  }

  .FindeJobBodyContent {
    width: 100%;
    max-width: 1570px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    .FJLeft {
      width: 400px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      background: #F8F9FF;
      border: 1px solid #e6e6e7;
      border-radius: 5px;
      min-height: 300px;

      @media (max-width: 1440px) {
        width: 300px;
      }

      @media (max-width: 1280px) {
        width: 250px;
      }

      @media (max-width: 800px) {
        position: fixed;
        left: -300px;
        top: 0;
        height: 100%;
        z-index: 999;
      }
    }

    .FJLeftOpen {
      @media (max-width: 1280px) {
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.01);
        padding-top: 55px;
      }
    }

    .FJRight {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 0 0 0 90px;

      @media (max-width: 1600px) {
        padding: 0 0 0 50px;
      }

      @media (max-width: 1024px) {
        padding: 0 0 0 20px;
      }

      @media (max-width: 800px) {
        padding-left: 0;
      }
    }
  }
}

.FJFilterContent,
.TPFilterContent {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 800px) {
    width: 300px;
    height: 100%;
    overflow-y: auto;
    background: #F8F9FF;
  }

  .Header {
    width: 100%;
    height: auto;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $siteblack;
    font-size: 18px;
    font-weight: 500;
    border-bottom: #e6e6e7 solid 1px;
    padding: 0 25px;

    @media (max-width: 1368px) {
      font-size: 16px;
      min-height: 50px;
    }

    i {
      font-size: 24px;
      margin-right: 15px;

      @media (max-width: 1368px) {
        font-size: 20px;
      }
    }
  }

  .Section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 15px 25px;

    h5 {
      width: 100%;
      font-weight: 500;
      font-size: 20px;
      color: $siteblack;
      display: flex;
      margin: 0 0 20px 0;

      @media (max-width: 1368px) {
        font-size: 16px;
      }
    }

    .RangeContent {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 35px;
      margin-top: 15px;
      padding: 0 10px;

      .rc-slider {
        display: flex;
        align-items: center;

        .rc-slider-rail {
          height: 8px;
          background-color: #cedaff;
          border-radius: 2px;
        }

        .rc-slider-track {
          height: 8px;
          background-color: $darkblue;
          border-radius: 2px;
        }

        .rc-slider-handle {
          height: 30px;
          width: 30px;
          border-radius: 3px;
          border-color: $blue;
        }

        .rc-slider-dot {
          display: none;
        }

        .rc-slider-mark {
          font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300))) !important;
          top: 30px;
          color: $siteblack !important;
          margin-bottom: 2rem !important;
          width: 92%;
          white-space: nowrap;

          Span:nth-child(1) {
            color: $siteblack !important;
            left: 8px !important;
          }

          Span:nth-child(2) {
            color: $siteblack !important;
            //  left: unset !important;
            // right: -30px !important;
            margin-left: -10px;
          }
        }
      }
    }
  }
}

.FJobsContainer,
.TPUserContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .Header {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    .inner-H {
      display: flex;
      //  column-gap: 30px;
      align-items: center;
      margin-left: auto;
      color: $textgray;

      .h-txt {
        display: inline-flex;
        width: 100px;
        font-weight: 500;
      }
    }

    @media (max-width: 1600px) {
      margin-bottom: 20px;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: left;
      align-items: center;
      row-gap: 30px;
    }

    h2 {
      width: auto;
      color: $textgray;
      font-size: 18px;
      font-weight: 800;
      margin: 0;
      padding: 0;

      @media (max-width: 1600px) {
        font-size: 14px;
      }
    }

    button {
      width: auto;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border-radius: 5px;
      border: 0;
      color: $blue;
      font-size: 16px;
      align-self: flex-end;
      text-decoration: none;
      opacity: 0.9;

      &:hover {
        text-decoration: none;
        opacity: 1;
      }
    }
  }

  .FJBodyContent,
  .TPUserBodyContent {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    align-items: flex-start;
    flex-direction: column;

    .JobSection {
      width: 100%;
      height: auto;
      display: flex;
      margin-bottom: 30px;
    }
  }

  .FJFooterContent,
  .TPUserFooterContent {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    align-items: flex-start;
  }
}

.ViewJobsSection {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 105px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;

  .ViewJobHeaderContainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px 50px 0 50px;

    @media (max-width: 1440px) {
      padding: 0;
    }

    .JobBannerBg {
      width: 100%;
      height: auto;
      min-height: 270px;
      max-height: 270px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background: #F8F9FF;

      @media (max-width: 1440px) {
        position: relative;
        min-height: auto;
        max-height: auto;
      }
    }

    .ViewJobHeaderContent {
      width: 100%;
      max-width: 1570px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      z-index: 2;

      .JobBanner {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.17);
        overflow: hidden;
        border-radius: 5px;

        @media (max-width: 1440px) {
          border-radius: 0;
        }

        img {
          width: 100%;

          @media (max-width: 1440px) {
            border-radius: 0;
          }
        }
      }

      .JobCompanyName {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 50px 0 300px;
        flex-wrap: wrap;
        margin: 30px 0 0 0;
        position: relative;
        z-index: 2;

        @media (max-width: 1440px) {
          padding: 0 50px 0 270px;
        }

        @media (max-width: 1150px) {
          padding: 0 20px 0 190px;
        }

        @media (max-width: 852px) {
          padding: 0 20px 0 160px;
          flex-direction: column;
          align-items: flex-start;
          margin: 0;
        }

        @media (max-width: 600px) {
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          margin-top: -40px;
        }

        .JVCompanyLogo {
          width: 210px;
          height: 210px;
          padding: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          border: 1px solid #ecedf2;
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          position: absolute;
          bottom: -20px;
          left: 50px;

          @media (max-width: 1440px) {
            width: 180px;
            height: 180px;
            padding: 15px;
          }

          @media (max-width: 1150px) {
            width: 150px;
            height: 150px;
          }

          @media (max-width: 1150px) {
            left: 20px;
          }

          @media (max-width: 852px) {
            width: 120px;
            height: 120px;
            bottom: 0;
          }

          @media (max-width: 600px) {
            position: relative;
            left: 0;
          }

          img {
            width: 100%;
          }
        }

        h2 {
          width: auto;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-weight: 500;
          font-size: 24px;
          color: $siteblack;

          @media (max-width: 1440px) {
            font-size: 22px;
          }

          @media (max-width: 852px) {
            font-size: 20px;
            margin-top: 15px;
          }
        }

        .JVSaveButton {
          width: auto;
          height: 40px;
          border: 2px solid $blue;
          border-radius: 4px;
          background-color: transparent;
          text-decoration: none;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $darkblue;
          font-weight: 800;

          @media (max-width: 852px) {
            border: 0;
            padding: 0;
            height: auto;
          }

          i {
            font-size: 20px;
            margin-right: 10px;

            @media (max-width: 852px) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .ViewJobBoddyContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: 60px 50px 20px 50px;

    @media (max-width: 1600px) {
      padding: 40px 50px 20px 50px;
    }

    @media (max-width: 1150px) {
      padding: 20px 20px 20px 20px;
    }

    .ViewJobBoddyContant {
      width: 100%;
      max-width: 1570px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 0 50px;

      @media (max-width: 1600px) {
        padding: 0;
      }

      .JVLeftContent {
        flex: 1;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding: 0 100px 0 0;

        @media (max-width: 1600px) {
          padding: 0 50px 0 0;
        }

        @media (max-width: 1150px) {
          padding: 0 20px 0 0;
        }

        @media (max-width: 852px) {
          padding: 0 0 0 0;
        }
      }

      .JVLeftContent {
        flex: 1;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        .JobTitle {
          width: 100%;
          height: auto;
          font-weight: 500;
          font-size: 30px;
          font-size: 30px;
          color: $siteblack;
          font-weight: 800;
          padding: 0;
          margin: 0 0 40px 0;

          @media (max-width: 1440px) {
            font-size: 24px;
            margin: 0 0 20px 0;
          }

          @media (max-width: 852px) {
            font-size: 22px;
          }

          @media (max-width: 600px) {
            text-align: center;
          }
        }

        .QuackDetailsCard {
          width: 100%;
          height: auto;
          min-height: 110px;
          display: flex;
          justify-content: flex-start;
          background: #ffffff;
          border: 1px solid #ecedf2;
          box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          list-style: none;
          margin: 0 0 40px 0;
          padding: 0;

          @media (max-width: 1440px) {
            margin: 0 0 20px 0;
            min-height: 90px;
          }

          @media (max-width: 600px) {
            flex-wrap: wrap;
          }

          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            padding: 30px;
            border-right: 1px solid #ecedf2;

            @media (max-width: 1440px) {
              padding: 15px;
            }

            @media (max-width: 990px) {
              padding: 10px;
            }

            &:last-child {
              border-right: 0;
            }

            .DetailsBox {
              width: auto;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              flex-direction: column;

              .Label {
                width: 100%;
                font-weight: 500;
                font-size: 16px;
                color: $textgray;
                margin: 0;
                padding: 0;

                @media (max-width: 1440px) {
                  font-size: 14px;
                }
              }

              .Title {
                width: 100%;
                font-weight: 500;
                font-size: 18px;
                color: $siteblack;
                margin: 0;
                padding: 0;

                @media (max-width: 1440px) {
                  font-size: 16px;
                }
              }
            }

            @media (max-width: 400px) {
              &:first-child {
                width: 100%;
                flex: unset;
              }
            }
          }

          .Flex1 {
            flex: 1;

            @media (max-width: 600px) {
              border-bottom: 1px solid #ecedf2;
            }
          }

          .Flex2 {
            flex: 2;

            @media (max-width: 1440px) {
              flex: 1;
            }

            @media (max-width: 600px) {
              flex: unset;
              width: 50%;
            }
          }
        }

        .JobViewSection {
          width: 100%;
          height: auto;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin: 0 0 40px 0;
          flex-direction: column;

          @media (max-width: 1440px) {
            margin: 0 0 20px 0;
          }

          h4 {
            width: 100%;
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            font-weight: 800;
            font-size: 20px;
            color: $siteblack;
            margin: 0 0 25px 0;

            @media (max-width: 1440px) {
              font-size: 16px;
              margin: 0 0 15px 0;
            }
          }

          .Text {
            width: 100%;
            height: auto;
            //display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            font-weight: 300;
            font-size: 18px;
            color: $textgray;
            line-height: 28px;

            @media (max-width: 1440px) {
              font-size: 14px;
            }
          }

          .ListContent {
            width: 100%;
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              width: 100%;
              height: auto;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              position: relative;
              padding: 0 0 0 40px;
              margin: 0 0 20px 0;
              font-weight: 300;
              font-size: 18px;
              color: $textgray;
              line-height: 28px;

              @media (max-width: 1440px) {
                font-size: 14px;
                padding: 0 0 0 20px;
              }

              &::after {
                content: " ";
                width: 10px;
                height: 10px;
                border-radius: 10px;
                border: $darkblue solid 2px;
                position: absolute;
                left: 0;
                top: 10px;

                @media (max-width: 1440px) {
                  width: 8px;
                  height: 8px;
                }
              }
            }
          }

          img {
            width: 100%;
          }

          .BtnContent {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;

            .GrayBtn {
              margin-right: 15px;

              @media (max-width: 600px) {
                margin-right: 0;
              }
            }

            button {
              margin-right: 15px;
            }
          }
        }

        .JobViewSectionMobile {
          @media (max-width: 850px) {
            display: none;
          }
        }
      }

      .JVRightContent {
        width: 400px;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        @media (max-width: 1600px) {
          width: 350px;
        }

        @media (max-width: 1440px) {
          width: 315px;
        }

        @media (max-width: 852px) {
          width: 100%;
          margin-bottom: 15px;
        }

        .JVRSection {
          width: 100%;
          background: #F8F9FF;
          border: 1px solid #ecedf2;
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin: 0 0 40px 0;
          padding: 0;
          flex-wrap: wrap;

          @media (max-width: 1440px) {
            margin: 0 0 20px 0;
          }

          h4 {
            width: 100%;
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            font-weight: 800;
            font-size: 18px;
            color: $siteblack;
            margin: 0 0 0 0;
            border-bottom: #e6e6e7 solid 1px;
            padding: 25px;

            @media (max-width: 1440px) {
              padding: 15px;
              font-size: 16px;
            }
          }

          .JVRSectionContent {
            width: 100%;
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 25px;

            @media (max-width: 1440px) {
              padding: 15px;
            }

            @media (max-width: 852px) {
              width: 50%;
            }

            @media (max-width: 600px) {
              width: 100%;
            }

            i {
              width: 24px;
              font-size: 24px;
              color: $textgray;
              margin-right: 20px;

              @media (max-width: 1440px) {
                font-size: 20px;
              }
            }

            .TextContent {
              flex: 1;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              flex-direction: column;

              .Label {
                width: 100%;
                font-weight: 500;
                font-size: 16px;
                color: $siteblack;
                margin: 0;
                padding: 0;

                @media (max-width: 1440px) {
                  font-size: 14px;
                }
              }

              .Title {
                width: 100%;
                font-weight: 500;
                font-size: 18px;
                color: $textgray;
                margin: 0;
                padding: 0;
                text-decoration: none !important;
                white-space: pre-wrap;

                @media (max-width: 1440px) {
                  font-size: 16px;
                }
              }

              .Title2 {
                width: 100%;
                font-weight: 500;
                font-size: 18px;
                color: $textgray;
                margin: 0;
                padding: 0;
                text-decoration: none !important;
                white-space: pre-wrap;
                word-break: break-all;

                @media (max-width: 1440px) {
                  font-size: 16px;
                }

                &:hover {
                  color: $blue;
                }
              }
            }
          }

          .Text {
            width: 100%;
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            font-weight: 300;
            font-size: 18px;
            color: $textgray;
            line-height: 28px;
            padding: 25px;
            margin: 0;

            @media (max-width: 1440px) {
              font-size: 14px;
              padding: 15px;
            }
          }
        }
      }

      .JobApplyOptionsMobile {
        width: 100%;
        height: auto;
        display: none;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 0 40px 0;
        flex-direction: column;

        @media (max-width: 852px) {
          display: flex;
          margin: 0 0 20px 0;
        }

        h4 {
          width: 100%;
          height: auto;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          font-weight: 800;
          font-size: 20px;
          color: $siteblack;
          margin: 0 0 25px 0;

          @media (max-width: 1440px) {
            font-size: 16px;
            margin: 0 0 15px 0;
          }
        }

        .Text {
          width: 100%;
          height: auto;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          font-weight: 300;
          font-size: 18px;
          color: $textgray;
          line-height: 28px;

          @media (max-width: 1440px) {
            font-size: 14px;
          }
        }

        .ListContent {
          width: 100%;
          height: auto;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            width: 100%;
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            position: relative;
            padding: 0 0 0 40px;
            margin: 0 0 20px 0;
            font-weight: 300;
            font-size: 18px;
            color: $textgray;
            line-height: 28px;

            @media (max-width: 1440px) {
              font-size: 14px;
              padding: 0 0 0 20px;
            }

            &::after {
              content: " ";
              width: 10px;
              height: 10px;
              border-radius: 10px;
              border: $darkblue solid 2px;
              position: absolute;
              left: 0;
              top: 10px;

              @media (max-width: 1440px) {
                width: 8px;
                height: 8px;
              }
            }
          }
        }

        img {
          width: 100%;
        }

        .BtnContent {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;

          button {
            margin-right: 15px;

            @media (max-width: 852px) {
              flex: 1;
              height: 46px;
            }

            @media (max-width: 600px) {
              flex: unset;
              width: 100%;
              margin-right: 0;
              margin-bottom: 10px;
            }
          }

          .GrayBtn {
            margin-right: 15px;

            @media (max-width: 600px) {
              margin-right: 0;
            }
          }

          a {
            @media (max-width: 852px) {
              flex: 1;
              height: 46px;
            }

            @media (max-width: 600px) {
              flex: unset;
              width: 100%;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .JobOpportunitiesContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: 0 50px 60px 50px;

    @media (max-width: 1150px) {
      padding: 0 20px 60px 20px;
    }

    .JobOpportunitiesContant {
      width: 100%;
      max-width: 1570px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 0 50px;

      @media (max-width: 1600px) {
        padding: 0;
      }

      h4 {
        width: 100%;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-weight: 800;
        font-size: 20px;
        color: $siteblack;
        margin: 0 0 30px 0;
      }
    }
  }
}

.ProfileContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 30px 50px 30px 50px;
  position: relative;
  z-index: 2;

  @media (max-width: 1150px) {
    padding: 30px 20px 30px 20px;
  }

  @media (max-width: 1280px) {
    flex-direction: column;
  }

  .ProLeftMenu {
    width: 350px;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #F8F9FF;
    border: 1px solid #ecedf2;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 5px;

    @media (max-width: 1440px) {
      width: 320px;
    }

    @media (max-width: 1280px) {
      width: 300px;
    }

    @media (max-width: 1024px) {
      width: 280px;
    }

    @media (max-width: 1280px) {
      width: 100%;
      box-shadow: none;
      border: 0;
      background-color: transparent;
    }
  }

  .ProRightContent {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 0 0 50px;

    @media (max-width: 1150px) {
      padding: 0 0 0 20px;
    }

    @media (max-width: 1280px) {
      width: 100%;
      padding: 0;
    }

    .LeftContent {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      // padding: 0 50px 0 0;

      @media (max-width: 1440px) {
        flex: unset;
        width: 100%;
        padding: 0;
      }
    }

    .RightContent {
      width: 350px;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      @media (max-width: 1440px) {
        width: 100%;
        margin-top: 30px;
      }
    }

    .LeftContentA {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 0 50px 0 0;

      @media (max-width: 1440px) {
        flex: unset;
        width: 100%;
        padding: 0;
      }
    }

    .RightContentA {
      flex: 2;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      @media (max-width: 1440px) {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
}

.ProfileDetailsCard {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #ecedf2;

  @media (max-width: 1440px) {
    padding: 30px;
  }

  @media (max-width: 990px) {
    // background-color: transparent;
    // padding: 0 0 30px 0;
    flex-direction: unset;
    border-bottom: 0;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    margin-bottom: 8px;
    padding-bottom: 0;
  }

  .ProPicContent {
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 1440px) {
      width: 150px;
      height: 150px;
    }

    .ProPic {
      width: 180px;
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 180px;
      background: #ffffff;
      border: 1px solid #e6e6e7;
      position: relative;
      z-index: 1;
      overflow: hidden;

      @media (max-width: 1440px) {
        width: 150px;
        height: 150px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .image {
      width: 180px;
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 180px;

      @media (max-width: 1440px) {
        width: 150px;
        height: 150px;
      }

      img {
        width: 100%;
      }
    }

    .ProPicUploder {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      position: absolute;
      align-items: center;
      justify-content: center;
      display: flex;
      bottom: 0;
      right: 0;
      z-index: 2;

      .fileUploader {
        .fileContainer {
          input {
            display: none;
          }

          .chooseFileButton {
            width: 46px;
            height: 46px;
            position: relative;
            top: 0;
            left: 0;
            overflow: hidden;
            background: transparent;

            &::after {
              content: " ";
              display: flex;
              width: 46px;
              height: 46px;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              background: #e6e6e7;
              background-image: url("../../src/assets/images/camera.svg");
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }

  .ProPicDetailsContent {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    margin-top: 40px;

    @media (max-width: 990px) {
      margin-top: 0;
      align-items: flex-start;
      margin-left: 20px;
      margin-bottom: 15px;
    }

    @media (max-width: 500px) {
      margin-top: 0;
      align-items: center;
      margin-left: 0;
      margin-bottom: 15px;
    }

    .Name {
      width: auto;
      font-weight: 800;
      font-size: 24px;
      color: $siteblack;

      @media (max-width: 1440px) {
        font-size: 22px;
      }
    }

    .Url {
      width: auto;
      font-weight: 500;
      font-size: 16px;
      color: $textgray;
    }

    .Email {
      width: auto;
      font-weight: 500;
      font-size: 16px;
      color: $textgray;

      @media (max-width: 1024px) {
        font-size: 14px;
      }
    }

    .Location {
      width: auto;
      font-weight: 500;
      font-size: 16px;
      color: $textgray;
      margin-top: 20px;

      @media (max-width: 1024px) {
        font-size: 14px;
      }

      @media (max-width: 990px) {
        margin-top: 5px;
      }
    }
  }
}

.ProfileLinks {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 40px;

  @media (max-width: 1440px) {
    padding: 30px;
  }

  @media (max-width: 1280px) {
    padding: 0;
    flex-direction: unset;
    justify-content: center;
    flex-wrap: wrap;
  }

  a {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: $textgray;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;

    @media (max-width: 1440px) {
      font-size: 16px;
    }

    @media (max-width: 1280px) {
      width: 50px;
      padding: 10px;
      background-color: $bluegray;
      margin: 1px 1px;
    }

    span {
      @media (max-width: 1280px) {
        display: none;
      }
    }

    i {
      margin-right: 15px;
      font-size: 20px;

      @media (max-width: 1440px) {
        font-size: 18px;
      }

      @media (max-width: 1280px) {
        font-size: 24px;
        margin-right: 0;
      }
    }

    &:hover {
      color: $blue;
      text-decoration: none;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }

  .active {
    color: $blue;
  }
}

.ProfileTopHeader {
  width: 100%;
  height: auto;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 30px 0;
  padding: 0;

  @media (max-width: 1368px) {
    margin: 0 0 20px 0;
  }

  @media (max-width: 990px) {
    margin: 20px 0 20px 0;
    min-height: auto;
    justify-content: center;
  }

  h2 {
    width: auto;
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 24px;
    color: #fff;

    @media (max-width: 1440px) {
      font-size: 22px;
    }

    @media (max-width: 1368px) {
      font-size: 20px;
    }

    @media (max-width: 990px) {
      font-size: 16px;
      margin-top: 20px;
    }
  }
}

.SkillsContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  .SkillsContent {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 0 20px 0;

    .SubHedding {
      width: 100%;
      height: auto;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 16px;
      color: $textgray;
      margin: 0 0 15px 0;
      padding: 0;
    }

    form {
      width: 100%;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      input {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-shadow: 10px 10px 17px rgb(0 0 0 / 2%);
        border-radius: 5px;
        border: 1px solid #d5d5d5;
        padding: 0 15px;
        margin-bottom: 15px;
      }
    }

    .SkillsCardContent {
      width: 100%;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;

      .SkillsCard {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height: 42px;
        width: auto;
        padding: 0 15px;
        color: $darkblue;
        font-weight: 500;
        font-size: 16px;
        background: #e8edfc;
        border-radius: 3px;
        margin: 0 15px 15px 0;

        button {
          background: transparent;
          border: 0;
          padding: 0;
          margin: 0 0 0 15px;
          color: $darkblue;
          font-size: 12px;
        }
      }
    }
  }
}

.TalentPoolSection {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 105px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;

  @media (max-width: 1280px) {
    min-height: calc(100vh - 80px);
  }

  @media (max-width: 1150px) {
    min-height: calc(100vh - 55px);
  }

  @media (max-width: 800px) {
    position: relative;
  }

  .TalentPoolHeaderContainer {
    width: 100%;
    height: auto;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 50px;
    background: #F8F9FF;

    @media (max-width: 1440px) {
      min-height: 180px;
    }

    @media (max-width: 1024px) {
      padding: 0 20px;
    }

    .TalentPoolHeaderContent {
      width: 100%;
      max-width: 1570px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

.TalentPoolBodyContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 60px 50px;

  @media (max-width: 1600px) {
    padding: 50px 50px;
  }

  @media (max-width: 1024px) {
    padding: 30px 20px;
  }

  .TalentPoolBodyContent {
    width: 100%;
    max-width: 1570px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    .PTLeft {
      width: 400px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      background: #F8F9FF;
      border: 1px solid #e6e6e7;
      border-radius: 5px;
      min-height: 300px;

      @media (max-width: 1440px) {
        width: 300px;
      }

      @media (max-width: 1280px) {
        width: 250px;
      }

      @media (max-width: 800px) {
        position: fixed;
        left: -300px;
        top: 0;
        height: 100%;
        z-index: 999;
      }
    }

    .PTLeftOpen {
      @media (max-width: 1280px) {
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        padding-top: 55px;
      }
    }

    .PTRight {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 0 0 0 90px;

      @media (max-width: 1600px) {
        padding: 0 0 0 50px;
      }

      @media (max-width: 1024px) {
        padding: 0 0 0 20px;
      }

      @media (max-width: 800px) {
        padding-left: 0;
      }
    }
  }
}

.row_h :hover {
  background-color: $blue;
  color: #fff;
}

.SummaryLabel {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #777589;
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}

.SummaryAnswer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $siteblack;
  font-size: 16px;
  font-weight: 500;
}

.c-avatar-img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.no-result {
  text-align: center;
  width: 90%;
  margin-top: 40px;
  font-weight: 800;
  color: #777589;
}

.jobInfo_img {
  width: 80%;
  max-width: 600px;
  height: 340px;
  padding: auto;
  background-color: #d5d5d5;
  border-radius: 3px;
  box-shadow: rgba($color: #000000, $alpha: 1);

  @media (max-width: 575px) {
    max-width: 400px;
  }

  img {
    align-items: center;
    margin: 20px;
    width: 92%;
    height: 300px;
    border-radius: 3px;

    @media (max-width: 575px) {
      width: 90%;
    }
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;
}

.buttonContainerend {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 1rem auto;
}

.imginnersize {
  width: 30px;
  height: 30px;
  margin: 3px;
}

.switchholder {
  margin: 5px 0px;
}

//popup talentpool see more
.popupHeaderContainer {
  width: 100%;
  height: fit-content;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

.proPic-Container {
  border-style: none;
  width: 10%;

  img {
    background-color: #F8F9FF;
    border-radius: 50px;
    width: 100px;
    height: 100px;
  }
}
.ucdp-responsive-col{
  flex-direction: row;
  @media (max-width: 425px){
    flex-direction: column;
    margin-bottom: 15px;
  }
}

.ucdp-hr{
  width: 1px;
  height: auto;
  align-self: stretch;
  border: 0;
  background: #1010108f;
  margin: 0;
  transition: background .3s,opacity .3s;
  flex-shrink: 0;
}

.ucdp-picture{
  img {
    background-color: #F8F9FF;
    border-radius: 99px;
    width: 130px;
    height: 130px;
    border: 4px solid $blue;
  }
}

.ucdp-name{
  h2{
    font-size: 24px;
    color: #1D1B31;
    font-weight: 500;
  }
  h6{
    font-size: 16px;
    font-weight: 400;
    color: #777589;
  }
  p{
    color: #777589;
  }
}

.basicInfo-Container {
  margin-left: 3rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 1rem;
  width: 80%;
  height: auto;
}

.detailsProfile {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.featuredFeilds {
  display: flex;
  align-items: flex-start;
  row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
}

.Address-Seemore {
  margin-left: 0rem;
}

.ucdp-info {
  // width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: #f8f8f9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem;
  flex-wrap: wrap;
  row-gap: 20px;
}

.ucdp-info-title {
  display: flex;
  flex-direction: column;
  grid-row-gap: 0.5rem;
  color: #1D1B31;
  font-weight: 500;
}

.skills-tools {
  width: 100%;
  height: fit-content;
  // background-color: #F8F9FF;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  // padding: 2rem;
}

.ucdp-bio {
  background-color: #f8f8f9;
  border-radius: 8px;
  padding: 1rem;
}

.ucdp-bio-title{
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  margin-bottom: 5px;
  color: #1D1B31;
}

.ucdp-grey-text {
  color: #777589;
}

.TagContentNew {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  .DataTag {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-wrap: wrap;
    // height: 42px;
    width: auto;
    padding: 10px;
    color: $darkblue;
    font-weight: 500;
    font-size: 14px;
    background: #e8edfc;
    border-radius: 3px;
    margin: 15px 15px 0 0;

    button {
      background: transparent;
      border: 0;
      padding: 0;
      margin: 0 0 0 15px;
      color: $darkblue;
      font-size: 12px;
    }
  }
}


.nameProfile {
  font-size: 24px;
  color: $siteblack;
  font-weight: 700;

  @media (max-width: 900px) {
    font-size: 18px;
    font-weight: 500;
  }
}

.textgrey-popup {
  color: $textgray;
  font-weight: 500;
}

.featuredSalary {
  background-color: $blue;
  border-radius: 3px;
  padding: 8px;
  color: $white;
  font-weight: 400;
}

.backcolor-time {
  background-color: $inputgray;
}

//radio buttons ui
.radioN {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: #344258;
}

.radio_input {
  display: none;
}

.radioInner {
  width: 20px;
  height: 20px;
  border: 2px solid #d8e4e2;
  border-radius: 50%;
  margin-right: 10px;
  box-sizing: border-box;
  padding: 2px;
}

.radioInner::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: $darkblue;
  border-radius: 50%;

  transform: scale(0);
  transition: transform 0.15s;
}

.radio_input:checked+.radioInner::after {
  transform: scale(1);
}

.linkS {
  width: 100%;
}

.rec.rec-arrow {
  display: none;
}

.icon-ribbonE:before {
  content: "\e900";
  color: $blue;
}

.careersup-h5 {
  color: $textgray;
}

.MiddleContentCareer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 6rem;

  @media (max-width: 1366px) {
    flex-wrap: wrap;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .blogitem {
    margin: 10px;
    padding: 10px;
    width: 30%;

    @media (max-width: 1366px) {
      margin: 0px;
      width: 30%;
    }

    @media (max-width: 1000px) {
      margin: 0px;
      width: 40%;
    }

    @media (max-width: 900px) {
      margin: 0px;
      width: 100%;
    }

    .blogiteminner1 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: $textgray;
      font-weight: 500;
      margin-top: 20px;

      .nameAthour {
        font-size: 18px;
      }

      .positionAthour {
        font-size: 15px;
      }

      .dateBlog {
        font-size: 14px;
        display: flex;
        align-items: center;
        row-gap: 10px;
      }
    }

    .descriptionBlog {
      font-size: 20px;
      color: $black;
      font-weight: 600;
    }

    img {
      width: 100%;
      height: 250px;
      border-radius: 8px;
      filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.17));
    }

    .readmoretxt {
      color: $blue;
      font-size: 14px;
      font-weight: 500;
      float: right;
    }
  }
}

.careerSLower {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .descriptionBlog {
    font-size: 20px;
    color: $black;
    font-weight: 600;
    float: left;
  }

  .readmoretxt {
    color: $blue;
    font-size: 14px;
    font-weight: 500;
    float: right;
  }
}

.blogInner {
  margin-left: 10px;
  margin-right: 10px;
}

.HeaderInfotxt {
  color: $textgray;
  font-size: 22px;
  font-weight: 400;

  @media (max-width: 900px) {
    padding-top: 10px;
    font-size: 18px;
    font-weight: normal;
  }
}

.h1career {
  font-size: 52px !important;
  font-weight: 500 !important;

  @media (max-width: 900px) {
    font-size: 30px !important;
    font-weight: 500 !important;
    text-align: center !important;
  }
}

.h5career {
  font-size: 24px !important;
  font-weight: normal !important;
  line-height: 38px !important;

  @media (max-width: 900px) {
    font-size: 18px !important;
    line-height: 28px !important;
  }
}

.icon-calendar {
  margin-right: 10px !important;
}

.talentMiddle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  .talentpoolimgcon {
    width: 30%;

    @media (max-width: 900px) {
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.talentpoolStatic {
  width: 60%;
  margin-left: 20px;

  @media (max-width: 900px) {
    width: 100%;
    margin-left: 0px;
  }

  h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 64px;
    margin-left: 20px;

    @media (max-width: 900px) {
      text-align: center;
      font-size: 30px;
      line-height: 58px;
      margin-left: 0px;
    }
  }

  h5 {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 35px;

    @media (max-width: 900px) {
      text-align: center;
      width: 90% !important;
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.ui.fullscreen.modal {
  margin-left: 2.5% !important;
}

.toptxtJobs {
  text-align: left;
  margin-left: -15px;

  @media (max-width: 900px) {
    text-align: center;
    margin-left: 0px;
  }
}

.jobcard {
  width: 100%;
  height: auto;
  background-color: $bluegray;
  border-radius: 6px;

  img {
    display: flex;
    justify-content: center;
    margin: 1.5px;
  }

  .jobPostedCardInner1 {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    .jobPostion {
      color: $siteblack;
      font-size: 24px;
      font-weight: 700;
    }

    .jobDescriptionMini {
      color: $textgray;
      font-size: 14px;
      font-weight: 400px;
    }
  }

  .jobPostedCardInner2 {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .jbTxt {
      margin-top: 15px;
      margin-bottom: 15px;
      color: $textgray;
      font-size: 13px;
      font-weight: 600;
    }
  }
}

.Ploader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("//upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Phi_fenomeni.gif/50px-Phi_fenomeni.gif") 50% 50% no-repeat;
}

.CCBRbottomNew {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 0 0 0;

  .BCard {
    width: auto;
    background: #e6e6e7;
    border-radius: 3px;
    color: $textgray;
    font-size: 12px;
    padding: 5px 10px;
    margin: 0 10px 10px 0;
  }
}

.DropdownCFlex {
  flex-direction: column;
  row-gap: 2px;
  align-items: center;
  height: auto;
}

.dropdowncontainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 10px 10px 17px rgb(0 0 0 / 2%);
  border-radius: 5px;
  // border: 1px solid #d5d5d5;
  // padding: 0 15px;
  margin-top: -2rem !important;
  margin-bottom: 2rem;

  @media (max-width: 1368px) {
    height: 50px;
  }
}

.tp-M {
  margin-top: 23px;
}

//analitics scss
.top-MA {
  margin-left: 0;
  margin-top: 3rem !important;
}

.BtnContainer-Right {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1rem;

  .BtnContainer-InnerA {
    text-align: center;

    button {
      width: 100%;
      height: 60px;
      border-radius: 5px;
      background-color: $darkblue;
      color: $white;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      box-shadow: #000;
    }
  }
}

.BtnContainer-Home {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1rem;

  .BtnContainer-InnerA {
    text-align: center;

    button {
      width: 340px;
      height: 60px;
      border-radius: 5px;
      background-color: $darkblue;
      color: $white;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      box-shadow: #000;
    }
  }
}

.analiticsTables {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 80px;
  background-color: $bluegray;
  border-radius: 5px;

  .OrderFilter {
    label {
      font-size: 18px;
      font-weight: 500;
      color: black;
    }
  }

  .analiticsFilterOptions {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filterComponent {
      display: flex;
      align-items: center;
      column-gap: 20px;

      label {
        font-size: 18px;
        font-weight: 500;
        color: black;
      }

      button {
        background-color: $darkblue;
        color: $white;
        width: 80px;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        box-shadow: #000;
      }
    }
  }
}

.tabletopAnalitics {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 500;
  color: #777589;
}

.jobPContainer {
  margin-bottom: 2rem;
}

.jobCards {
  display: flex;
  // flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  .Card {
    background-color: $bluegray;
    width: 30%;
    height: auto;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;

    // margin-left: 15px;
    @media (max-width: 900px) {
      width: 100%;
    }

    label {
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      color: black;
    }

    .cardInContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;

      .cardIn {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        color: $siteblack;
        margin-bottom: 15px;

        span {
          color: $siteblack;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }
}

.custom-event-ellipsis {
  width: 266px;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 576px) {
    width: 80px;
  }
}

// .chart-month{
// display: flex;
// width: auto;
// float: right;
// }

.sideJobCards {
  margin-top: 10px;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  .Card {
    background-color: $bluegray;
    height: auto;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.3s;

    @media (max-width: 900px) {
      width: 100%;
    }

    label {
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      color: black;
      margin-bottom: 10px;
    }

    .cardInContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;

      .cardIn {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        color: $siteblack;
        margin-bottom: 15px;

        span {
          color: $siteblack;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }

    &:hover {
      background-color: #d9dfef;
    }
  }
}

.chart-home-header {
  font-size: 16px;
  font-weight: 600;
  color: $siteblack;
  // color: #777589;
  margin-bottom: 1rem;
}


//recruiter

.searchrow {
  background-color: $bluegray;
  height: auto;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
}

.jobs-searchrow {
  height: auto !important;
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: 500px) {
    width: 100%;
  }
}

.serachrow-txt {
  font-size: 16px;
  color: $siteblack;
  font-weight: 600;
}

.searchrowinner {
  display: flex;
  justify-content: center;
}

.searchrowinnertxt-co {
  display: flex;
  justify-content: flex-start;
  padding-left: 2rem;

  @media (max-width: 900px) {
    display: none !important;
  }
}

.txtsection-fliters {
  @media (max-width: 900px) {
    display: none;
  }
}

.inputanalitics {
  height: 35px;
  width: 100%;
  display: block !important;
}

.inpt {
  .rmdp-container {
    display: block !important;
  }
}

.clearB {
  width: 38px;
  height: 35px;
  border-radius: 5px;
  background-color: transparent;
  border: solid 1px #16c0f8;

  .icoClear {
    color: #16c0f8;
  }
}

.clearB:hover {
  background-color: #16c0f8;

  .icoClear {
    color: #000;
  }
}

// .Wi{
//   width: 330px !important;
//   @media (max-width:1400px){
//     width: 275px !important;
//   }
//   @media (max-width:1366px){
//     width: 265px !important;
//   }
//   @media (max-width:1200px){
//     width: 240px !important;
//   }
//   @media (max-width: 900px){
//     width: 350px !important;
//   }
//   @media (max-width: 390px){
//     width: 285px !important;
//   }
// }

.jobs-ch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ch-L {
  width: 35%;

  img {
    border-radius: 6px;
  }
}

.ch-R {
  width: 65%;
}

.ExpoBtn-Analitics {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 0;
  text-decoration: none;
  opacity: 0.9;
  color: $darkblue;
  font-size: 18px;
  font-weight: 400;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    opacity: 1;
  }

  @media (max-width: 1440px) {
    font-size: 16px;
  }

  @media (max-width: 1368px) {
    font-size: 14px;
  }

  @media (max-width: 700px) {
    margin-top: 15px;
  }

  i {
    font-size: 20px;
    margin-left: 10px;

    @media (max-width: 1440px) {
      font-size: 18px;
    }

    @media (max-width: 1368px) {
      font-size: 16px;
      margin-left: 5px;
    }
  }
}

.backnalitics {
  justify-content: flex-start !important;

  .icon-rightarrow {
    rotate: 1.57rad !important;
  }
}

.newtalent {
  width: 100%;
  // height: 580px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #F8F9FF;
  background: linear-gradient(270deg, #F8F9FF 0%, rgba(159, 179, 241, 0.5) 117.14%);

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .newtalent-left {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .newtalent-left-IF {
      width: 60%;
      margin-top: 4rem;

      @media (max-width: 900px) {
        width: 100%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .newtalent-left-RF {
      width: 40%;
      margin-top: 4rem;

      @media (max-width: 900px) {
        width: 100%;
      }

      .tptxt {
        font-size: 28px;
        font-weight: 500;
        color: #1d1b31;

        @media (max-width: 1380px) {
          font-size: 28px;
        }
      }

      .mdtxt {
        font-size: 16px;
        font-weight: 400;
        text-transform: capitalize;
        color: #777589;
      }

      .recruiterbtn {
        button {
          width: 165px;
          height: 50px;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          border-radius: 5px;
          color: $blue;
          font-weight: 500;
          font-size: 18px;
          border: none;
          margin-top: 1rem;
        }

        span {
          color: $blue;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
  }

  .newtalent-right {
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media (max-width: 900px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    .newtalent-right-RF {
      width: 60%;
      margin-top: 2rem;
      display: flex;
      justify-content: flex-end;

      @media (max-width: 900px) {
        width: 100%;
      }

      img {
        margin-top: 3rem;
        width: 70%;
        height: 100%;
      }
    }

    .newtalent-right-IF {
      width: 40%;
      margin-bottom: 6rem;

      @media (max-width: 900px) {
        width: 100%;
      }

      .tptxt {
        font-size: 28px;
        font-weight: 500;
        color: #1d1b31;

        @media (max-width: 1380px) {
          font-size: 28px;
        }
      }

      .mdtxt {
        font-size: 16px;
        font-weight: 400;
        text-transform: capitalize;
        color: #777589;
      }

      .candidatebtn {
        button {
          width: 165px;
          height: 50px;
          background: $blue;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          border-radius: 5px;
          color: #fff;
          font-weight: 500;
          font-size: 18px;
          border: none;
          margin-top: 1rem;
        }

        span {
          color: $blue;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
  }
}

// featuressection

.newtalent-Feature {
  width: 100%;
  // height: 565px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #F8F9FF;
  background: linear-gradient(270deg, #F8F9FF 0%, rgba(159, 179, 241, 0.5) 117.14%);
  padding-left: 3%;
  padding-right: 3%;

  @media (max-width: 990px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    margin-bottom: 2rem;
    padding-bottom: 5%;
  }

  .left-con {
    img {
      width: 85%;
      height: auto;
    }

    @media (max-width: 990px) {
      display: none;
    }
  }

  .right-con {
    img {
      width: 100%;
      height: auto;
      // margin-top: 2rem;
    }

    @media (max-width: 990px) {
      display: none;
    }
  }
}

.middle-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 6rem;

  @media (max-width: 1200) {
    row-gap: 4rem;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .slight-1 {
      width: 70%;

      @media (max-width: 900px) {
        width: 100%;
      }
    }

    .slight-2 {
      width: 30%;

      @media (max-width: 900px) {
        display: none;
      }
    }
  }

  .down {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .slight-1 {
      width: 30%;

      @media (max-width: 900px) {
        display: none;
      }
    }

    .slight-2 {
      width: 70%;

      // .heading-1{
      //   text-align: right !important;
      // }
      @media (max-width: 900px) {
        width: 100%;
      }
    }
  }
}

.featuresection {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 8rem;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 4rem;
  }

  .featuresectionLeft {
    width: 60%;

    @media (max-width: 900px) {
      width: 100%;
    }

    img {
      width: 80%;
      height: auto;
      margin-left: 4rem;
    }
  }

  .featuresectionRight {
    width: 40%;

    @media (max-width: 900px) {
      width: 100%;
    }
  }
}

.featureheaders {
  font-size: 40px;
  color: #1d1b31;
  font-weight: 800;
}

.featurepara {
  font-weight: 300;
  font-size: 20px;
  color: #000;
}

.featureoptions {
  margin-top: 2rem;

  .featureoptions-one {
    display: flex;
    align-items: center;
    justify-content: space-around;
    column-gap: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;

    .optionsone {
      width: 65px;
      height: 65px;
      background: #ffffff;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        height: auto;
      }
    }

    .optionstwo {
      width: calc(100% - 80px);
    }
  }
}

.optionHtxt {
  font-weight: 500;
  font-size: 17px;
  text-transform: capitalize;
  color: $blue;

  @media (max-width: 1300px) {
    font-size: 16px;
  }
}

.optionP {
  max-width: 460px;
}

.optionsubtxt {
  font-weight: 300;
  font-size: 18px;
  color: #777589;

  @media (max-width: 1600px) {
    font-size: 16px;
  }

  @media (max-width: 1366px) {
    font-size: 14px;
  }
}

.featurecards {
  width: 100%;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 5rem;
  margin-bottom: 3.5rem;

  @media (max-width: 900px) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.inner_featurecards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  @media (max-width: 900px) {
    flex-direction: column;
    row-gap: 1rem;
  }
}

.cards-feature {
  width: 36%;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  box-sizing: border-box;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;

  @media (max-width: 900px) {
    width: 100%;
    height: 25rem;
    flex-direction: column;
    row-gap: 1rem;
  }

  span {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 30px;
      height: auto;
    }
  }

  .I2 {
    width: 80%;
  }
}

.cards-feature-img {
  width: 20%;

  img {
    width: 90%;
    height: auto;

    @media (max-width: 900px) {
      display: none;
    }
  }
}

// forthsection
.lastsection {
  width: 100%;
  margin-top: 3rem;
  background: rgba(243, 244, 249, 0.5);
}

.lastinner {
  margin-left: 5rem;
  margin-right: 5rem;

  @media (max-width: 900px) {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .reslastinner {
    @media (max-width: 900px) {
      flex-direction: column-reverse !important;
    }

    .resfeaturesectionLeft {
      @media (max-width: 900px) {
        display: none !important;
      }
    }
  }
}

.alignlast {
  text-align: end !important;

  @media (max-width: 900px) {
    text-align: left;
  }
}

//lastcards

.lastcardsfeature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  margin-top: 8rem;
  margin-bottom: 10rem;

  @media (max-width: 1366px) {
    margin-top: 7rem;
    margin-bottom: 8rem;
  }

  @media (max-width: 1250px) {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    row-gap: 1rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lastcardsin {
    padding: 20px;
    width: 30%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #ecedf2;
    box-sizing: border-box;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

    @media (max-width: 900px) {
      width: 100%;
      height: 25rem;
    }

    span {
      width: 65px;
      height: 65px;
      background: #ffffff;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;

      img {
        width: 30px;
        height: auto;
      }
    }
  }
}

.card-recruiterH {
  .Card {
    background-color: $bluegray;
    width: 30%;
    height: auto;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-left: 15px;

    label {
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      color: black;
    }

    .cardInContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;

      .cardIn {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        color: $siteblack;
        margin-bottom: 15px;

        span {
          color: $siteblack;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }
}

.card-recruiterH {
  width: 100%;

  .Card {
    background-color: $bluegray;
    // width: 260px;
    margin-left: -10px;
    height: auto;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    // margin-left: 15px;
    width: 100%;
    cursor: pointer;

    @media (max-width: 1366px) {
      // width: 225px;
    }

    @media (max-width: 1250px) {
      // width: 190px;
    }

    label {
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      color: black;
    }

    .cardInContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;

      .cardIn {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        color: $siteblack;
        margin-bottom: 15px;

        @media (max-width: 1250px) {
          font-size: 14px;
        }

        span {
          color: $siteblack;
          font-size: 12px;
          font-weight: 300;

          @media (max-width: 1250px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .Card:hover {
    background-color: $inputgray;
  }
}

.readmoreSectionInner {
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  width: 70%;

  .Bbtn {
    color: $darkblue;
    font-size: 20px;
    font-weight: 400;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      opacity: 1;
    }

    @media (max-width: 1440px) {
      font-size: 16px;
    }

    @media (max-width: 1368px) {
      font-size: 14px;
    }

    @media (max-width: 700px) {
      margin-top: 15px;
    }

    img {
      margin-right: 10px;

      @media (max-width: 1368px) {
        width: 20px;
        height: auto;
      }

      @media (max-width: 700px) {
        width: 20px;
        height: auto;
      }
    }
  }

  .readmoreHeader {
    margin-top: 3.5rem;

    .topic {
      margin-top: 3.5rem;
      margin-bottom: 2rem;
      width: auto;
      height: auto;
      font-size: 36px;
      font-weight: 700;
      color: $siteblack;
      margin: 0;
      padding: 0;

      @media (max-width: 1440px) {
        font-size: 30px;
      }

      @media (max-width: 1368px) {
        font-size: 24px;
      }
    }

    .blogiteminner1 {
      margin-bottom: 3.5rem;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      color: $textgray;
      font-weight: 500;

      .nameAthour {
        font-size: 18px;
        margin-right: 20px;
      }

      .positionAthour {
        font-size: 15px;
      }

      .dateBlog {
        font-size: 14px;
        display: flex;
        align-items: center;
        row-gap: 10px;
      }
    }

    .blogmainImg {
      width: 100%;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 6px;
        // box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
      }
    }

    .blogcontent {
      margin-top: 3rem;
      margin-bottom: 3.5rem;

      p {
        width: 100%;
        font-size: 18px;
        line-height: 30px;
        text-align: justify;
        text-transform: capitalize;
        font-weight: 500;
      }
    }

    .nextbtnblog {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.pppagecontent {
  .cover-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #F8F9FF;
    height: 500px;

    @media (max-width: 900px) {
      flex-direction: column;
      height: fit-content;
    }

    .Lpp {
      flex: 1;
      display: flex;
      justify-content: center;

      img {
        width: 80%;
        height: 80%;

        @media (max-width: 900px) {
          width: 60%;
          height: 60%;
          margin-top: 1rem;
        }
      }
    }

    .Rpp {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      row-gap: 1rem;

      @media (max-width: 900px) {
        text-align: center;
        align-items: center;
      }

      h2 {
        font-size: 70px;
        font-weight: 700;
        line-height: 80px;

        @media (max-width: 900px) {
          font-size: 50px;
        }
      }

      p {
        width: 90%;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin-top: 0.5rem;
        margin-bottom: 2rem;
        color: #777589;
      }
    }
  }

  .updatedtxt {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 4rem;
    margin-right: 4rem;
    font-size: 18px;
    font-weight: 500;
    color: #777589;

    @media (max-width: 900px) {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }

  hr {
    width: 90%;
    margin-left: 4rem;
    margin-right: 4rem;
    background-color: #1d1b31;

    @media (max-width: 900px) {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }

  .intro {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 4rem;
    margin-right: 4rem;
    width: 90%;
    font-size: 18px;
    line-height: 36px;
    font-weight: 500;
    color: #000;

    .spanweb {
      color: $blue;
      cursor: pointer;
    }

    @media (max-width: 900px) {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }

    .uldes {
      list-style: none;

      .spanweb {
        color: $blue;
        cursor: pointer;
      }
    }
  }

  .content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 4rem;
    margin-right: 4rem;
    width: 93%;

    @media (max-width: 900px) {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }

    .topicSection {
      font-size: 18px;
      font-weight: 800;
      color: #1d1b31;
      margin-bottom: 0.8rem;
    }

    .mainstart {
      font-size: 24px;
      font-weight: 800;
      color: #1d1b31;
      margin-bottom: 0.8rem;
    }

    .contentSection {
      font-size: 16px;
      font-weight: 400;
      color: #777589;
      line-height: 24px;
    }

    .spanweb {
      color: $blue;
      cursor: pointer;
    }

    ul {
      list-style: none;
      margin-top: 1.2rem;
      margin-bottom: 1.2rem;

      li {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        .sectiontwo {
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          @media (max-width: 900px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-evenly;
          }

          .first {
            width: 30%;
            font-size: 16px;
            line-height: 20px;

            @media (max-width: 900px) {
              width: 100%;
              margin-bottom: 0.3rem;
            }
          }

          .second {
            width: 70%;
            font-size: 16px;
            line-height: 20px;
            color: #777589;

            @media (max-width: 900px) {
              width: 100%;
            }
          }

          .newest {
            width: 100% !important;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .contact-email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    .coup {
      font-size: 16px;
      font-weight: 500;
      color: #777589;
      margin-bottom: 0.3rem;
    }

    .codown {
      font-size: 16px;
      font-weight: 700;
      color: black;
      margin-bottom: 2rem;
    }
  }

  .admininfo {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;

    @media (max-width: 900px) {
      flex-direction: column;
      row-gap: 1rem;
    }

    .admininfo1 {
      width: 50%;

      @media (max-width: 900px) {
        width: 100%;
      }

      .positionE {
        font-size: 18px;
        font-weight: 700;
      }

      .dis {
        ul {
          list-style: none;
          margin-top: 0.5;

          li {
            font-size: 16px;
            line-height: 18px;
            font-weight: 300;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .admininfo2 {
      width: 50%;

      @media (max-width: 900px) {
        width: 100%;
      }

      .positionE {
        font-size: 18px;
        font-weight: 700;
      }

      .dis {
        ul {
          list-style: none;
          margin-top: 0.5;

          li {
            font-size: 16px;
            line-height: 18px;
            font-weight: 300;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

//faqui
.faqHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
  background: #f3f4f9;

  // height: 580px;
  .leftContainer-faqHeader {
    width: 60%;

    .toptxt {
      font-weight: 500;
      font-size: 52px;
      line-height: 60px;
      color: #1d1b31;
    }

    .des {
      margin-top: 1rem;
      margin-bottom: 2rem;
      font-size: 24px;
      font-weight: 400;
      line-height: 38px;
      text-transform: capitalize;
      color: #777589;
    }

    .btnfaqHeader {
      a {
        button {
          height: 50px;
          width: 200px;
          color: #fff;
          border: none;
          font-size: 18px;
          background: $blue;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          border-radius: 5px;
        }
      }
    }
  }

  .RightContainer-faqHeader {
    width: 40%;

    img {
      width: 70%;
      margin-left: 4rem;
    }
  }
}

.eaptOe {
  width: 18px !important;
  height: 10px !important;
  background: #cedaff !important;
  border-radius: 20px !important;
  box-shadow: none !important;
}

.lcUbOd {
  background: $blue !important;
  width: 32px !important;
  height: 10px !important;
  border-radius: 20px !important;
  box-shadow: none !important;
}

.faqMiddle {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 5rem;
}

.faqMiddleleft {
  width: 50%;
  padding: 30px;
}

.faqMiddleright {
  width: 50%;
  padding: 30px;
}

.faqEnd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.faqEndLeft {
  width: 50%;
  padding: 30px;

  img {
    width: 100%;
    height: auto;
  }
}

.faqEndRight {
  width: 50%;
  padding: 30px;
}

.faqTptxt {
  width: 100%;

  h6 {
    font-size: 28px;
    font-weight: 500;
    color: $siteblack;
    margin-bottom: 12px;
  }

  p {
    font-weight: 300;
    font-size: 20px;
    line-height: 35px;
    color: #000000;
    opacity: 0.75;
    margin-bottom: 1rem;
  }
}

.contactForms {
  .react-tel-input {
    width: 100% !important;

    .form-control {
      width: 100% !important;
    }
  }

  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-evenly;
  flex-direction: column;

  .flexset {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    @media (max-width: 568px) {
      flex-direction: column;
    }

    .flexsetinner {
      width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      padding: 0 15px 15px 0;

      @media (max-width: 568px) {
        width: 100%;
      }

      label {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #777589;
      }

      input {
        background: #ffffff;
        border: 1px solid #d5d5d5;
        box-sizing: border-box;
        box-shadow: 10px 10px 17px rgba(0, 0, 0, 0.02);
        border-radius: 5px;
        height: 60px;
        font-size: 16px;
        line-height: 20px;
        color: #505051;
      }

      .inpt {
        padding-left: 10px !important;
      }
    }

    .MAXinner {
      width: 100% !important;

      textarea {
        height: 200px !important;
        background: #ffffff;
        border: 1px solid #d5d5d5;
        box-sizing: border-box;
        box-shadow: 10px 10px 17px rgba(0, 0, 0, 0.02);
        border-radius: 5px;
        font-size: 16px;
        line-height: 20px;
        color: #505051;
        padding: 10px;
        outline: none;
      }
    }
  }

  .btncont {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 10px;

    .btnform {
      height: 50px;
      width: 200px;
      color: #fff;
      border: none;
      font-size: 18px;
      background: $blue;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
      border-radius: 5px;
    }
  }
}

.cl-accord-faq {
  .card {
    width: 90%;
    border: none !important;

    @media (max-width: 960px) {
      width: 100%;
    }

    .card-body {
      padding: 0px 0px !important;

      .accordion {
        .accordion-item {
          margin-bottom: 0.4rem !important;

          .accordion-header {
            .accordion-button {
              background: #F8F9FF;
              border: 1px solid #ecedf2;
              box-sizing: border-box;
              box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
              border-radius: 5px;
            }

            .accordion-button::after {
              background-image: url(../assets/images/plus-accor.png) !important;
            }

            .accordion-button:not(.collapsed)::after {
              background-image: URL(../assets/images/minus-accor.png) !important;
            }
          }
        }
      }
    }
  }
}

.aboutUsHeader {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #F8F9FF;
  padding-left: 4rem;
  padding-right: 4rem;

  @media (max-width: 900px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .tptxt {
    font-weight: 500;
    font-size: 46px;
    line-height: 60px;
    color: #1d1b31;
    margin-bottom: 1rem;
  }

  .des {
    width: 80%;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    text-transform: capitalize;
    color: #777589;
  }
}

.aboutMiddle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 4rem;
  margin-bottom: 4rem;

  @media (max-width: 900px) {
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .aboutMiddleLeft {
    width: 40%;

    @media (max-width: 900px) {
      width: 100%;
    }

    img {
      width: 80%;
      height: auto;
      margin-left: 4rem;

      @media (max-width: 900px) {
        width: 100%;
        // margin-left: 6rem;
        margin-right: auto;
      }
    }
  }

  .aboutMiddleRight {
    width: 60%;

    @media (max-width: 900px) {
      width: 100%;
      // margin-left: 2rem;
      text-align: center;
    }

    .tptxt {
      font-weight: 500;
      font-size: 46px;
      line-height: 60px;
      color: #1d1b31;
      margin-bottom: 1rem;
    }

    .des {
      width: 90%;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 38px;
      text-transform: capitalize;
      color: #777589;
      margin-left: 2rem;
    }
  }
}

.middleIntro {
  background-image: url(../assets/images/aboutcover.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  width: 100%;
  background-color: #F8F9FF;

  @media (max-width: 900px) {
    height: 600px;
  }

  .middleIntroInner {
    width: 70%;
    margin-top: 8.5rem;
    margin-left: auto;
    margin-right: auto;
    background: #ffffff;
    border: 1px solid #ecedf2;
    box-sizing: border-box;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 40px;

    @media (max-width: 900px) {
      padding: 10px;
      margin-top: 6rem;
    }

    .tptxt {
      text-align: center;
      font-weight: 500;
      font-size: 46px;
      line-height: 60px;
      color: #243C86;
      margin-bottom: 1rem;
    }

    .des {
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 38px;
      text-transform: capitalize;
      color: #777589;
    }
  }
}

.aboutFourth {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 7rem;
  padding-right: 4rem;
  margin-top: 3rem;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .aboutFourthLeft {
    width: 50%;

    @media (max-width: 900px) {
      width: 90%;
      text-align: center;
    }

    .tptxt {
      font-weight: 500;
      font-size: 46px;
      line-height: 60px;
      color: #1d1b31;
      margin-bottom: 1rem;
    }

    .des {
      width: 90%;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 38px;
      text-transform: capitalize;
      color: #777589;

      @media (max-width: 900px) {
        width: 100%;
      }
    }
  }

  .aboutFourthRight {
    width: 50%;

    @media (max-width: 900px) {
      width: 100%;
    }

    img {
      width: 80%;
      height: auto;
      margin-left: 4rem;

      @media (max-width: 900px) {
        width: 100%;
        margin-left: 0rem;
      }
    }
  }
}

.aboutlast {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 2rem;
  margin-top: 5rem;
  margin-bottom: 8rem;

  .tptxt {
    font-weight: 500;
    font-size: 46px;
    line-height: 60px;
    color: #1d1b31;
    margin-bottom: 1rem;
    text-align: center;

    @media (max-width: 900px) {
      width: 70%;
    }
  }

  .inneraboutlast {
    background: #F8F9FF;
    border: 1px solid #ecedf2;
    box-sizing: border-box;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    width: 70%;

    @media (max-width: 900px) {
      padding: 10px;
    }

    .des {
      width: 100%;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 38px;
      text-transform: capitalize;
      color: #777589;
      text-align: center;
    }
  }
}

//accordion customization

.AccordItem {
  width: 350px !important;
  background: transparent !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08) !important;
  border-radius: 5px !important;

  @media (max-width: 1450px) {
    width: 250px !important;
  }

  @media (max-width: 1280px) {
    width: 200px !important;
  }

  .Header-accord {
    border: none !important;

    .btn-accord {
      background: transparent !important;
      background-color: transparent !important;
      height: 60px !important;
      border: none !important;

      h5 {
        margin-top: 15px !important;
        font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300))) !important;
        font-weight: 500 !important;
      }
    }
  }
}

.btn-popup {
  display: none;

  @media (max-width: 800px) {
    visibility: visible;
    background-color: $white;
    width: 100%;
    height: 80px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-inner {
    width: 50%;
    height: fit-content;

    .btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300)));
    }
  }
}

.cus-modal-content {
  .FJLeft {
    .FJFilterContent {
      width: 100% !important;

      .AccordItem {
        margin-left: auto !important;
        margin-right: auto !important;
        width: 100% !important;
      }
    }
  }
}

.accord-filter {
  display: flex;
  row-gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

// .sec-last {
//   @media (max-width:640px) {
//   margin-bottom: 70px;
//   }
//   @media (max-width:400px) {
//   margin-bottom: 0px;
//   }
// }

.rc-slider-tooltip-inner {
  padding: 6px 8px;
  min-width: auto !important;
  height: auto !important;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}

.slry {
  .accordion-body {
    padding: 1rem 1.25rem 1rem 1.25rem !important;
  }
}

.form-control:focus {
  box-shadow: none !important;
  // border: none !important;
}

.ui.fluid.dropdown {
  width: 100% !important;
}

.ftrs {
  img {
    width: 80%;
  }
}

.salary-custom {
  width: 100%;
}

.pil-frm {
  width: 100%;
}

.pil-drop {
  border-left: none !important;
  border-radius: 0 0.28571429rem 0.28571429rem 0 !important;
}

.lbl {
  width: 50%;
  height: auto;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  margin-right: -2px;
  z-index: 2;
}

.cvr-pic {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100px;
  margin-bottom: 15px;
  border-radius: 6px;

  img {
    flex-shrink: 1;
    min-width: 100%;
    min-height: 100%;
  }
}

.errorsContainer {
  max-width: 600px;
}

.errorMessage {
  margin-top: 55px;
  max-width: 600px;
  width: 550px;
}

.react-datepicker-popper {
  z-index: 5 !important;
}

.UCRegRow {
  .body-1 {
    color: #000 !important;
    font-weight: 600 !important;
  }
}

.icn {
  display: flex;
  justify-content: flex-end;
}

.duration {
  display: flex;
  width: 100%;
}

.eWidth {
  width: 600% !important;
}

.left {
  .icon-rightarrow {
    margin: 0 10px 0 0;
    transform: scaleX(-1);
  }
}

.Bbtn {
  a {
    text-decoration: none;
  }
}

.evnt {
  overflow-x: auto;

  .img-bx {
    height: 350px;

    a {
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .dtls {
    .CapDate {
      width: auto;
      font-weight: 500;
      font-size: 14px;
      color: $textgray;
      display: flex;
      align-items: center;

      @media (max-width: 1368px) {
        font-size: 14px;
      }

      i {
        margin: 0 10px 0 0;
        font-size: 18px;
      }
    }


    .CapTitle {
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 24px;
      padding: 0;
      color: $siteblack;
      text-align: left;

      .no-style {
        text-decoration: none !important;
        color: black;
      }

      @media (max-width: 1440px) {
        font-size: 20px;
      }

      @media (max-width: 1368px) {
        font-size: 18px;
      }
    }
  }
}

.FGroup {
  .react-select-virtualized {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    // display: flex;
    // flex-direction: row-reverse;
    // overflow: hidden;
  }

  .css-1s2u09g-control {
    border: none;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-1pahdxg-control {
    box-shadow: none;
    border-color: #fff;

    &:hover,
    &:focus-visible {
      box-shadow: none;
      border-color: #fff;
    }
  }

  // .css-tlfecz-indicatorContainer {
  //   svg {
  //     display: none;
  //   }
  // }
}

.first-op {
  .selection {
    border-left: none !important;
    border-right: 1px solid #dedfe3 !important;
  }

}

.ReactVirtualized__List {
  border: none !important;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15) !important;
  background-color: #fff !important;
  display: block !important;
  border-color: rgb(43, 255, 0) !important;


  color: #a8a7b2;
  font-size: 16px;
  font-family: 'Arimo', sans-serif !important;

  div {
    border: none !important;

    div {
      border: none !important;

      div {
        background-color: #fff !important;
        height: 37px !important;
        border: none !important;

        &:hover,
        &:focus,
        &:focus-visible {
          background: rgba(0, 0, 0, 0.03) !important;
        }
      }
    }
  }
}

.FJFormCard .InputContent .FGroup .ui.selection.active.dropdown:hover,
.TPFormCard .InputContent .FGroup .ui.selection.active.dropdown {
  border: none !important;

  &:hover,
  &:focus,
  &:focus-visible {
    border: none !important;
  }
}

.FJFormCard .InputContent .FGroup .ui.selection.active.dropdown,
.TPFormCard .InputContent .FGroup .ui.selection.active.dropdown {
  border: none !important;
}


.pro-dlts {
  display: flex;
  // border: 1px solid green;
  flex-wrap: wrap;
  align-content: center;
  // height: 100px;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;

  .pro-pic {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid red;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100px;
    }
  }

  .pro-desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // border: 1px solid pink;

    .name {
      font-size: 24px;
      color: $siteblack;
      font-weight: 700;

      @media (max-width: 900px) {
        font-size: 18px;
        font-weight: 500;
      }
    }

    .ttl {
      color: $textgray;
      font-weight: 600;
    }
  }

  .slry-range {
    display: flex;
    align-items: center;
  }
}

.abt-me {
  margin: 30px 0 0 0;
}

.table-cus {
  @media(max-width: 768px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.side-bx-nav {
  @media(max-width:992px) {
    display: none;
  }
}

.top-bx-nav {
  display: none;


  @media(max-width:992px) {
    display: block;
  }


  .Card {
    background-color: $bluegray;
    height: auto;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 25px;

    label {
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      color: black;
    }

    .cardIn {
      font-size: 14px;
      font-weight: 600;
      color: $siteblack;

      span {
        color: $siteblack;
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  .Card:hover {
    background-color: $inputgray;
  }
}

.swal2-styled.swal2-confirm {
  background-color: $blue !important;
}

.ucon-delete{
  position: absolute;
  width: 20px;
  bottom: 0;
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  color: red;
}


//Labeeb
@import "labeebui";
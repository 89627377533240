/*--------------------- About Page Sec-1 --------------------*/
.abt-sec-1 {
  padding: 8% 10%;
  background-color: #F8F9FF;

  h1 {
    margin-bottom: 30px;
  }
}

.abt-sec-2 {
  padding: 100px 5%;

  @media (max-width: 768px) {
    padding: 50px 5%;
  }

  h1 {
    margin-bottom: 30px;
  }

  .cl-1 {
    img {
      width: 90%;

      @media (max-width: 768px) {
        width: 60%;
      }
    }
  }

  .cl-2 {
    h2 {
      margin-bottom: calc(16px + (30 - 16) * ((100vw - 300px) / (1600 - 300)));
    }
    p {
      line-height: calc(20px + (32 - 20) * ((100vw - 300px) / (1600 - 300)));
    }
    .p-1 {
      margin-bottom: calc(20px + (32 - 20) * ((100vw - 300px) / (1600 - 300)));
    }
  }

  @media (max-width: 768px) {
    h2,
    p {
      text-align: center;
    }

    h2 {
      margin-top: 30px;
    }

    .cl-1 {
      display: flex;
      justify-content: center;
    }
  }
}

.abt-sec-3 {
  background-image: url(../assets/images/aboutcover.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0;
  background-color: #F8F9FF;

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  .cnt-bx {
    padding: 75px 50px;
    background-color: #fff;
    border: 1px solid #ecedf2;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 15px;

    h2 {
      margin-bottom: calc(20px + (40 - 20) * ((100vw - 300px) / (1600 - 300)));
      color: #243C86;
    }

    p {
      line-height: calc(20px + (32 - 20) * ((100vw - 300px) / (1600 - 300)));
      text-align: center;
    }
  }
}

.abt-sec-4 {
  padding: 100px 5%;

  @media (max-width: 768px) {
    padding: 50px 5%;
  }

  h1 {
    margin-bottom: 30px;
  }

  .cl-1 {
    img {
      width: 80%;

      @media (max-width: 768px) {
        width: 80%;
      }
    }
  }

  .cl-2 {
    h2 {
      margin-bottom: calc(16px + (30 - 16) * ((100vw - 300px) / (1600 - 300)));
    }
    p {
      line-height: calc(20px + (32 - 20) * ((100vw - 300px) / (1600 - 300)));
    }
    .p-1 {
      margin-bottom: calc(20px + (32 - 20) * ((100vw - 300px) / (1600 - 300)));
    }
  }

  @media (max-width: 768px) {
    h2,
    p {
      text-align: center;
    }

    h2 {
      margin-top: 30px;
    }

    .cl-1 {
      display: flex;
      justify-content: center;
    }
  }
}

.abt-sec-5 {
  padding: 100px 5% 0px 5%;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    padding: 50px 5% 0px 5%;
  }

  h2 {
    margin-bottom: calc(20px + (40 - 20) * ((100vw - 300px) / (1600 - 300)));
  }

  .cnt-bx {
    padding: 75px 50px;
    background-color: #F8F9FF;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 15px;

    p {
      line-height: calc(20px + (32 - 20) * ((100vw - 300px) / (1600 - 300)));
      text-align: center;
    }
  }
}

@media (max-width: 992px) {
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
}

.car-sec-a {
  background-color: #F8F9FF;

  padding: 100px 5% 0px 5%;

  @media (max-width: 768px) {
    padding: 50px 5% 0px 5%;
  }

  h1 {
    margin-bottom: 30px;
  }

  .cl-1 {
    display: flex;
    justify-content: center;
    img {
      width: 80%;

      @media (max-width: 768px) {
        width: 60%;
      }
    }
  }

  .cl-2 {
    h2 {
      margin-bottom: calc(16px + (30 - 16) * ((100vw - 300px) / (1600 - 300)));
    }
    p {
      line-height: calc(20px + (32 - 20) * ((100vw - 300px) / (1600 - 300)));
    }
    .p-1 {
      margin-bottom: calc(20px + (32 - 20) * ((100vw - 300px) / (1600 - 300)));
    }
  }

  @media (max-width: 768px) {
    h2,
    p {
      text-align: center;
    }

    h2 {
      margin-top: 30px;
    }

    .cl-1 {
      display: flex;
      justify-content: center;
    }
  }
}

.faq-sec-a {
  background-color: #F8F9FF;
  padding: 100px 5% 0px 5%;

  .heading-1 {
    margin-bottom: calc(16px + 14 * (100vw - 300px) / 1300);
  }

  .cl-1 {
    img {
      width: 75%;

      @media (max-width: 992px) {
        width: 50%;
      }

      @media (max-width: 568px) {
        width: 60%;
      }
    }
  }

  .cl-2 {
    button {
      height: 50px;
      width: 200px;
      color: #fff;
      border: none;
      font-size: 18px;
      background: #0C2679;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
    }
    .p-1 {
      margin-bottom: calc(20px + (32 - 20) * ((100vw - 300px) / (1600 - 300)));
    }

    @media (max-width: 992px) {
      align-items: center;
      button {
        margin-bottom: calc(30px + (40 - 30) * ((100vw - 300px) / (1600 - 300)));
        @media (max-width: 568px) {
          height: 45px;
          width: 150px;
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    h1,
    p {
      text-align: center;
    }
    .cl-1 {
      display: flex;
      justify-content: center;
    }
  }
}

.faq-sec-b {
  padding: 100px 5% 100px 5%;

  @media (max-width: 768px) {
    padding: 50px 5%;
  }

  .p-1 {
    margin-bottom: calc(20px + (32 - 20) * ((100vw - 300px) / (1600 - 300)));
  }

  .sec-1 {
    @media (max-width: 992px) {
      margin-bottom: calc(50px + (60 - 50) * ((100vw - 300px) / (1600 - 300)));
    }
  }
}

.faq-sec-c {
  padding: 100px 5% 0px 5%;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    padding: 50px 5% 0px 5%;
  }

  .cl-2 {
    @media (max-width: 992px) {
      display: flex;
      justify-content: center;
      margin-bottom: calc(50px + (60 - 50) * ((100vw - 300px) / (1600 - 300)));
    }

    img {
      width: 85%;

      @media (max-width: 992px) {
        width: 60%;
      }

      @media (max-width: 568px) {
        width: 70%;
      }
    }
  }

  .cl-1 {
    .p-1 {
      margin-bottom: calc(20px + (32 - 20) * ((100vw - 300px) / (1600 - 300)));
    }
  }
}

.tlnt-pool {

  .lnk-text, a{
    color: #0C2679 !important;
    text-decoration: none !important;
    font-weight: bold !important;
    font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300))) !important;
  }

  .heading-1{
    font-size: calc(24px + (32 - 24) * ((100vw - 300px) / (1600 - 300))) !important;
    width: 70%;

    @media (max-width: 1200px){
      width: 100%;
    }
  }
  // background: linear-gradient(270deg, #F8F9FF 0%, rgba(159, 179, 241, 0.5) 117.14%);
  background: #F8F9FF;

  @media (max-width: 1200px){
    background-color: rgb(159, 179, 241, 0.5) !important;
    background: rgb(159, 179, 241, 0.5);
  }

  padding-top: 100px;

  // .rcrui-btn {
  //   width: 165px;
  //   height: 50px;
  //   background: #ffffff;
  //   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  //   border-radius: 5px;
  //   color: #0C2679;
  //   font-weight: 500;
  //   font-size: 18px;
  //   border: none;
  //   margin-top: 1rem;
  // }
  .candi-btn,
  .rcrui-btn {
    width: 165px;
    height: 50px;
    background: #0C2679;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    border: none;
    margin-top: 1rem;
    cursor: pointer;
  }

  .cntnt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: calc(60px + (70 - 60) * ((100vw - 300px) / (1600 - 300)));
    // border: 1px solid green;

    @media (max-width:1200px){
      display: none;
    }
  }
  
  .cnt-a{
    // border: 1px solid red;
    padding-right: 100px;
  }

  .cnt-b{
    // border: 1px solid red;
    display: flex;
    justify-content: flex-end;
    padding-left: 100px;
  }

  .img-a{
    img {
      width: 100%;
    }

    @media (max-width:576px){
      width: 60%;
    }
  }

  .cnt-a-lg, .cnt-b-lg{
    @media (min-width:1200px){
      display: none;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cnt-a-lg{
    padding-left: 75px;
    padding-right: 5px;
    @media (max-width:576px){
      padding-left: 0;
      text-align: center;
      margin: 50px 0 75px 0;
      padding: 0 5% !important;
    }
  }

  .cnt-b-lg{
    @media (max-width:576px){
      width: 100%;
    }
    
    div{
      align-items: flex-end;

      @media (max-width:576px){
        align-items: center;
      }
    }
    text-align: flex-end;
    padding-right: 75px;
    padding-left: 5px;
    @media (max-width:576px){
      padding-right: 0;
      text-align: center;
      margin: 50px 0 75px 0;
      padding: 0 5% !important;
    }
  }

  .rw-2{
    @media (max-width:1200px){
      padding-top: 100px;
      background-color: #F8F9FF;
    }
    @media (max-width:640px){
      margin-bottom: 45px;
    }
    flex-direction: row-reverse;
    @media (max-width:576px){
      flex-direction: column;
      align-items: center;
    }
  }

  .rw-1{
    @media (max-width:1200px){
      // background-color: rgb(159, 179, 241, 0.5);
    }
    @media (max-width:576px){
      flex-direction: column;
      align-items: center;
    }
  }
}

.ftrs-sec-a {

  .lnk-text, a{
    color: #0C2679 !important;
    text-decoration: none !important;
    font-weight: bold !important;
    font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300))) !important;
    cursor: pointer;
  }

  .heading-1{
    font-size: calc(24px + (32 - 24) * ((100vw - 300px) / (1600 - 300))) !important;
  }

  // background: linear-gradient(270deg, #F8F9FF 0%, rgba(159, 179, 241, 0.5) 117.14%);
  background: $blue;

  // @media (max-width: 1200px){
  //   background-color: rgb(159, 179, 241, 0.5) !important;
  //   background: rgb(159, 179, 241, 0.5);
  // }

  padding-top: 100px;

  .rcrui-btn {
    width: 165px;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    color: #0C2679;
    font-weight: 500;
    font-size: 18px;
    border: none;
    margin-top: 1rem;
  }
  .candi-btn {
    width: 165px;
    height: 50px;
    background: #0C2679;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    border: none;
    margin-top: 1rem;
  }

  .cntnt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: calc(100px + (120 - 100) * ((100vw - 300px) / (1600 - 300)));
    // border: 1px solid green;

    @media (max-width:1200px){
      display: none;
    }
  }
  
  .cnt-a{
    // border: 1px solid red;
    padding-right: 100px;
  }

  .cnt-b{
    // border: 1px solid red;
    display: flex;
    justify-content: flex-end;
    padding-left: 100px;

    div{
      text-align: flex-end;
    }
  }

  .img-a{
    img {
      width: 100%;
    }

    @media (max-width:576px){
      width: 60%;
    }
  }

  .cnt-a-lg, .cnt-b-lg{
    @media (min-width:1200px){
      display: none;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cnt-a-lg{
    padding-left: 75px;
    padding-right: 5px;
    @media (max-width:576px){
      padding-left: 0;
      text-align: center;
      margin: 50px 0 75px 0;
      padding: 0 5% !important;
    }
  }

  .cnt-b-lg{
    @media (max-width:576px){
      width: 100%;
    }

    div{
      align-items: flex-end;
      
      @media (max-width:576px){
        align-items: center;
      }
    }
    text-align: flex-end;
    padding-right: 75px;
    padding-left: 5px;
    @media (max-width:576px){
      padding-right: 0;
      text-align: center;
      margin: 50px 0 75px 0;
      padding: 0 5% !important;
    }
  }

  .rw-2{
    @media (max-width:1200px){
      padding-top: 100px;
    }
    flex-direction: row-reverse;
    @media (max-width:576px){
      flex-direction: column;
      align-items: center;
    }
  }

  .rw-1{
    @media (max-width:1200px){
      // background-color: rgb(159, 179, 241, 0.5);
    }
    @media (max-width:576px){
      flex-direction: column;
      align-items: center;
    }
  }
}

.ftrs-sec-b {
  padding: 100px 5%;

  @media (max-width: 768px) {
    padding: 50px 5%;
  }

  .cl-1 {
    justify-content: center;
    margin-bottom: 100px;
    img {
      width: 100%;

      @media (max-width: 1200px) {
        width: 60%;
      }
      @media (max-width: 576px) {
        width: 80%;
      }
    }
  }

  .cl-2 {
    .ftr-card{
      .ftr-ico{
        width: 65px;
        height: 65px;
        background: #FFFFFF;
        box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 30px;
          height: auto;
        }
      }
    }

    .cntnt{
      width: 80%;
      margin-left: 25px;

      .ttl{
        color: #0C2679 !important;
      }
    }
  }

  @media (max-width: 768px) {
    .cl-1 {
      display: flex;
      justify-content: center;
    }
  }
}

.ftrs-sec-c {
  padding: 0px 5% 100px 5%;

  @media (max-width: 768px) {
    padding: 0px 5% 50px 5%;
  }

  .crd-rw{
    margin-bottom: 50px;
  }

  .crd{
    height: 200px;
    width: 38%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ECEDF2;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 0 40px;
  }

  .sldr{
    height: auto;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      width: 95%;
    }
  }

  .annc{
    width: 40px;
    height: auto;
  }

  .ttl{
    color: #0C2679 !important;
  }

  .cnt{
    margin-left: 40px;
  }

  @media (max-width:1200px){
    .sldr{
      display: none;
    }
   
    .crd{
      width: 48%;
    }
  }

  @media (max-width:920px){
    .crd{
      width: 100%;
      margin-bottom: 25px;
    }

    .crd-rw{
      margin-bottom: 0px;
    }
  }  
  
}

.ftrs-sec-d {
  background-color: #F9F9FC;

  @media (max-width: 1200px) {
    .row{
      flex-direction: column-reverse;
    }
  }

  padding: 100px 5%;

  @media (max-width: 768px) {
    padding: 50px 5%;
  }

  .cl-1 {
    justify-content: center;
    margin-bottom: 100px;
    img {
      width: 100%;

      @media (max-width: 1200px) {
        width: 60%;
      }
      @media (max-width: 576px) {
        width: 80%;
      }
    }
  }

  .cl-2 {
    .ftr-card{
      .ftr-ico{
        width: 65px;
        height: 65px;
        background: #FFFFFF;
        box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 30px;
          height: auto;
        }
      }
    }

    .cntnt{
      width: 80%;
      margin-left: 25px;

      .ttl{
        color: #0C2679 !important;
      }
    }
  }

  @media (max-width: 768px) {
    .cl-1 {
      display: flex;
      justify-content: center;
    }
  }
}

.ftrs-sec-e {
  background-color: #F9F9FC;

  padding: 0px 5% 100px 5%;

  @media (max-width: 768px) {
    padding: 0px 5% 50px 5%;
  }

  .crd{
    height: 300px;
    width: 32%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ECEDF2;
    background-color: #fff;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 0 25px;
  }

  .annc{
    width: 40px;
    height: auto;
    margin-bottom: 20px;
  }

  .ttl{
    color: #0C2679 !important;
  }

  @media (max-width:1200px){
    .crd{
    }
  }

  @media (max-width:920px){
    .crd{
      width: 100%;
      margin-bottom: 25px;
    }
  }  
  
}

// Here you can add other styles
.modal{
    left: unset  !important;
    height: unset;
    top: unset;
}
.fileContainer{
    background: none !important;
    box-shadow: none !important;
} 



@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fontsicons/icomoon.eot?yt8d7r');
  src:  url('../assets/fontsicons/icomoon.eot?yt8d7r#iefix') format('embedded-opentype'),
    url('../assets/fontsicons/icomoon.ttf?yt8d7r') format('truetype'),
    url('../assets/fontsicons/icomoon.woff?yt8d7r') format('woff'),
    url('../assets/fontsicons/icomoon.svg?yt8d7r#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bell:before {
  content: "\e900";
}
.icon-bookmark:before {
  content: "\e901";
}
.icon-calendar:before {
  content: "\e902";
}
.icon-camera:before {
  content: "\e903";
}
.icon-category:before {
  content: "\e904";
}
.icon-clock:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e906";
}
.icon-cup .path1:before {
  content: "\e907";
  color: rgb(206, 218, 255);
}
.icon-cup .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-cup .path3:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(206, 218, 255);
}
.icon-cup .path4:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-cup .path5:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(206, 218, 255);
}
.icon-cup .path6:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-cup .path7:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-cup .path8:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-cup .path9:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-email:before {
  content: "\e910";
}
.icon-facebook:before {
  content: "\e911";
}
.icon-fbookicon:before {
  content: "\e912";
  color: #2196f3;
}
.icon-filter:before {
  content: "\e913";
}
.icon-googlicon .path1:before {
  content: "\e914";
  color: rgb(251, 187, 0);
}
.icon-googlicon .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(81, 142, 248);
}
.icon-googlicon .path3:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(40, 180, 70);
}
.icon-googlicon .path4:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(241, 67, 54);
}
.icon-inicon:before {
  content: "\e918";
  //color: #069;
}
.icon-job:before {
  content: "\e919";
}
.icon-key:before {
  content: "\e91a";
}
.icon-linkedin:before {
  content: "\e91b";
}
.icon-location:before {
  content: "\e91c";
}
.icon-lockcolor .path1:before {
  content: "\e91d";
  color: rgb(206, 218, 255);
}
.icon-lockcolor .path2:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-lockcolor .path3:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-lockcolor .path4:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-lockcolor .path5:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-lockcolor .path6:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-lockcolor .path7:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-logout:before {
  content: "\e924";
}
.icon-rightarrow:before {
  content: "\e925";
}
.icon-searchcolor .path1:before {
  content: "\e926";
  color: rgb(255, 255, 255);
}
.icon-searchcolor .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(206, 218, 255);
}
.icon-searchcolor .path3:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-searchcolor .path4:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(206, 218, 255);
}
.icon-searchcolor .path5:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-searchcolor .path6:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-searchcolor .path7:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-searchcolor .path8:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-searchcolor .path9:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-searchcolor .path10:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-searchcolor .path11:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(25, 77, 243);
}
.icon-setting:before {
  content: "\e931";
}
.icon-share:before {
  content: "\e932";
}
.icon-twitter:before {
  content: "\e933";
}
.icon-userlogin:before {
  content: "\e934";
}
.icon-usr:before {
  content: "\e935";
}
.icon-video:before {
  content: "\e936";
}
.icon-web:before {
  content: "\e937";
}

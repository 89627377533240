// ------------ Body Style ----------------
body {
  padding: 80px 0 300px 0;
  padding-bottom: 0 !important;

  @media (max-width: 1440px) {
    padding-top: 80px;
  }

  @media (max-width: 1024px) {
    padding-bottom: 0 !important;
  }

  @media (max-width: 1150px) {
    padding-top: 55px;
  }

  @media (max-width: 640px) {
    padding-bottom: 0 !important;
  }

  @media (max-width: 400px) {
    padding-bottom: 0 !important;
  }
}

// ------------ Utility Style ----------------
.uBlue{
  color: $blue;
}
.cursorPointer{
  cursor: pointer;
}
.UCBanner{
  background-color: #E8EDFC;
  color: $blue;
  padding: 15px;
  border-radius: 5px;
  line-height: 1.6;
}


// ------------ React Select Style ----------------
.css-13cymwt-control{
  border: none !important;
  box-shadow: none !important;
  &:hover{
    border: none !important;
  }
}
.css-t3ipsp-control{
  border: none !important;
  box-shadow: none !important;
  &:hover{
    border: none !important;
  }
}
.css-1u9des2-indicatorSeparator{
  background: none !important;
}

// ------------ NavBar Style ----------------
.Nav {
  .NavContainer {
    min-height: 80px;
    max-width: 1690px;
    margin: 0 auto;

    @media (max-width: 1440px) {
      min-height: 80px;
    }

    @media (max-width: 1280px) {
      padding: 0 20px;
    }

    @media (max-width: 1150px) {
      min-height: 55px;
    }

    @media (max-width: 1024px) {
      padding: 0 16px;
    }
  }

  .NavLeft {
    .NavBrnd {
      width: 130px;
    }

    .NavLinks {
      .NavItem {
        a {
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
  }

  // Notification Style - Delete this if not necessary
  .NavRight {
    .NavRightList {
      .dropdown-menu {
        li {
          padding: 5px;
          background: #ffffff;
          margin-bottom: 10px;

          a {
            margin: 0;
            background: #ffffff;
            color: #000000;
            font-size: 1rem;

            i {
              margin-right: 10px;
              font-size: 20px;
            }
          }

          .dropdown-item {
            margin: 0;
            background: #ffffff;
            color: #000000;
            font-size: 1rem;
            cursor: pointer;

            i {
              margin-right: 10px;
              font-size: 20px;
              
            }
          }

          // div {
          //   div {
          //     background: #fff !important;
          //   }
          // }
        }

        .NavDropContainer {
            .ProName {
              width: auto;
              font-weight: 700;
              font-size: 20px;
              color: $siteblack;
              text-align: center;
            }
            .ProEmail {
              width: auto;
              font-weight: 700;
              font-size: 13px;
              color: $textgray;
            }
        }
      }
    }
  }
}

// ------------ Footer Style ----------------
.Footer {
  position: unset;
  .FooterTop {
    max-width: 1570px;
    margin: 0 auto;

    .FooterLeft {
      .NavLinks {
        .NavItem {
          h3 {
            font-size: 1.2rem;
            color: #fff;
          }

          a {
            font-size: 1rem;
            color: #fff;

          }
        }
      }
    }

    .FooterRight {
      .NavLinks {
        .NavItem {
          a {
            font-size: 1rem;
            color: #fff;

            i {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .FooterBottom {
    max-width: 1570px;
    margin: 0 auto;

    p {
      font-size: 1rem;
      color: #fff;
    }
  }
}

// ------------ Login Style ----------------
.LoginSection {
  padding: 50px 0;

  .LoginContainer {
    h2 {
      margin: 0 0 5px 0;
      font-size: 1.9rem;
    }

    p {
      margin: 0 0 20px 0;
      font-size: 1rem;
      color: #777589;
    }

    .ui {
      padding: unset;
    }

    .ui.active {
      margin-top: 1.5rem;

      P {
        font-size: 1rem;
        margin-bottom: 20px;
      }
    }

    .pointing {
      .item {
        min-height: unset;
        font-size: 1rem;
        padding: 1.2rem 1rem;
        font-family: 'Poppins', sans-serif;
      }
    }

    .InputContent {
      .InputGroup {
        margin-bottom: 15px;

        .Icon {
          width: unset;
          height: unset;
          font-size: 1.9rem;
          padding: 1.5rem;
        }

        .Inputs {
          .label {
            font-size: 1rem;
            font-weight: 500;
          }

          input {
            font-size: 1rem;
          }
        }
      }
    }

    .RemebreSection {
      margin-bottom: 1.5rem;

      .Forget {
        font-size: 1rem;
        font-weight: 400;
      }
    }

    button {
      height: unset;
      padding: 1rem;
      font-size: 1rem;
      margin-bottom: 25px;
    }

    .SocialLogin {
      .LinkContent {
        span {
          font-size: 1rem;
          font-weight: 400;

          @media (max-width: 1368px) {
            // font-size: 14px;
          }
        }

        button {
          height: 30px;
          width: 30px;
          padding: 0;
          margin-bottom: 3px;

          // background: #e8eaff !important;
          // padding: 10px !important;
          // border-radius: 50%;
          img {
            width: 25px;
          }
        }
      }
    }

    .RegisterLink {
      font-size: 1rem;
      font-weight: normal;
      margin-top: 25px;
    }
  }
}

// ------------ Sign Up Style ----------------
.RegisterSection {
  padding: 50px 0;

  .RegisterContainer {
    h2 {
      margin: 0 0 5px 0;
      font-size: 1.9rem;
      font-weight: 700;
    }

    p {
      margin: 0 0 20px 0;
      font-size: 1rem;
      color: #777589;
      text-align: center;
    }

    .pointing {
      .item {
        min-height: unset;
        font-size: 1rem;
        padding: 1.2rem 1rem;
        font-family: 'Poppins', sans-serif;
      }
    }

    .segment {
      margin-top: 2rem;
    }

    button {
      height: unset;
      margin: 0 0 10px 0;
      padding: 1rem;
      font-size: 1rem;
    }

    .button.Btn {
      margin: 0 0 10px 0;
    }

    .SocialLogin {
      margin-bottom: 2rem;

      .LinkContent {
        span {
          font-size: 1rem;
          font-weight: 400;
        }

        button {
          height: 30px;
          width: 30px;
          padding: 0;
          margin-bottom: 3px;

          // background: #e8eaff !important;
          // padding: 10px !important;
          // border-radius: 50%;
          img {
            width: 25px;
          }
        }
      }
    }

    .RegisterLink {
      font-size: 1rem;
      font-weight: normal;
    }

    .RegCol100 {
      button {
        margin-top: 1rem;
        margin-bottom: 25px;
      }
    }
  }
}

.RegInputGroupt {
  margin-bottom: 15px;

  label {
    font-size: 1rem;
    font-weight: 400;
  }

  input {
    height: unset;
    padding: 1rem;
    background-size: 15px !important;
    background-position: 95% 50% !important;

    @media (max-width: 1440px) {
      // height: 54px;
    }

    @media (max-width: 1368px) {
      // height: 50px;
    }

    @media (max-width: 600px) {
      // min-height: 46px;
    }
  }
}

// ------------ Typo Style ---------------- This is only here to have reference
.heading-1 {
  font-size: 3rem;
}

.heading-2 {
  font-size: 1.3rem;
}

.heading-3 {
  font-size: 2rem;
}

.body-1 {
  font-weight: 400 !important;
}

// ------------ UI Kit Style ----------------
.NBDfBtnText {
  padding: 0.5rem 1rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  height: unset;
}

.NBDfBtn {
  padding: 0.5rem 1rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  height: unset;
}

.ExpoBtn {
  font-size: 1.2rem;

  i {
    font-size: 1.2rem;
  }
}

.CheckContainer {
  min-height: unset;
  font-size: 1rem;

  @media (max-width: 1368px) {
    // font-size: 14px;
  }
}

// ------------ Career Support Style ----------------
.car-sec-a {
  padding-top: 50px;

  @media (max-width: 768px) {
    // padding: 50px 5% 0px 5%;
  }

  .cl-1 {
    justify-content: flex-end;

    @media (max-width: 992px) {
      justify-content: center;
    }

    img {
      width: 100%;
      max-width: 400px;

      @media (max-width: 768px) {
        // width: 60%;
      }
    }
  }

  .cl-2 {
    .heading-1 {
      font-size: 3rem !important;
    }

    .heading-2 {
      font-size: 1.3rem !important;
    }
  }
}

.section-2-careerSupport {
  margin-bottom: 0;

  .row {
    .SectionTopHeader {
      margin-bottom: 0;

      h3 {
        font-size: 2rem !important;
      }
    }
  }
}

.section-3-careerSupport {
  margin-bottom: 0;

  .row {
    .SectionTopHeader {
      margin-bottom: 0;

      h3 {
        font-size: 2rem !important;
      }
    }
  }
}

.blogInner {
  margin: 0;

  .blogiteminner1 {
    .nameAthour {
      font-size: 1.2rem;
    }

    .dateBlog {
      font-size: 1rem;
    }

    .positionAthour {
      font-size: 1rem;
    }
  }
}

.blogitem {
  img {
    height: 300px;
    object-fit: contain;
    background-color: #d6e5ff;
  }
}

.careerSLower {
  margin-top: 10px;

  .descriptionBlog {
    font-size: 1.3rem;
  }

  .readmoretxt {
    font-size: 1rem;
  }
}

.readmoreSectionInner {
  .readmoreHeader {
    .heading-3 {
      font-size: 2rem !important;
    }

    .blogcontent {
      p {
        font-size: 1rem;
        line-height: 1.6;
      }
    }

    .blogiteminner1 {
      .nameAthour {
        font-size: 1.2rem;
      }
    }

    .dateBlog {
      font-size: 1rem;
    }
  }

  .Bbtn {
    font-size: 1.2rem;
  }
}

.RecJobContainer {
  .SectionTopHeader {
    margin-bottom: 0;
  }
}

.MiddleContentCareer {
  margin-bottom: 0;
}

// ------------ Talent Pool Style ----------------
.tlnt-pool {
  padding-top: 50px;

  .heading-1 {
    font-size: 2rem !important;
    width: 65%;
    line-height: inherit;

    @media (max-width:576px) {
      margin: auto;
    }

  }

  .rcrui-btn {
    width: unset;
    height: unset;
    font-size: 1rem;
    padding: 1rem 2rem 1rem;
  }

  .candi-btn {
    width: unset;
    height: unset;
    font-size: 1rem;
    padding: 1rem 2rem 1rem;
  }

  .img-a {
    display: flex;
    flex-direction: column-reverse;

    img {
      width: 90%;
    }
  }
}

// ------------ About Us Style ----------------
.abt-sec-1 {
  padding: 100px 25%;
  background-color: $blue;
  color: #fff;

  h1 {
    font-size: 3rem !important;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.3rem !important;
    color: #fff !important;
  }
}

.abt-sec-2 {
  padding: 50px 5%;

  .cl-1 {
    display: flex;
    justify-content: space-around;

    img {
      width: 80%;
      max-width: 500px;
    }
  }

  .cl-2 {
    h2 {
      font-size: 2rem !important;
      margin-bottom: 20px;
    }

    p {
      line-height: 1.8;
      margin-right: 20%;

      @media (max-width: 768px) {
        margin-right: unset;
      }
    }

    .p-1 {
      margin-bottom: 20px;
    }
  }
}

.abt-sec-3 {
  .cnt-bx {
    padding: 50px;

    h2 {
      margin-bottom: 20px;
    }

    p {
      line-height: 1.8;
    }
  }
}

.abt-sec-4 {
  padding: 50px 5%;

  h1 {
    margin-bottom: 20px;
  }

  .cl-1 {
    display: flex;
    justify-content: space-around;

    img {
      max-width: 400px;
    }
  }

  .cl-2 {
    h2 {
      font-size: 2rem !important;
      margin-bottom: 20px;
    }

    p {
      line-height: 1.8;
      margin-right: 20%;

      @media (max-width: 768px) {
        margin-right: unset;
      }
    }

    .p-1 {
      margin-bottom: 20px;
    }
  }
}

.abt-sec-5 {
  padding: 50px 5% 0px 5%;
  margin-bottom: 50px;

  h2 {
    margin-bottom: 20px;
  }

  .cnt-bx {
    padding: 50px;

    p {
      line-height: 1.8;
    }
  }
}

// ------------ Contact Us Style ----------------
.faq-sec-a {
  padding: 50px 5% 0px 5%;
  background-color: $blue;

  .heading-1 {
    font-size: 3rem !important;
    color: #fff;
  }

  .cl-1 {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 992px) {
      justify-content: center;
    }

    img {
      width: 80%;
      max-width: 450px;

      @media (max-width: 768px) {
        // width: 60%;
      }
    }
  }

  .cl-2 {
    button {
      width: fit-content;
      height: unset;
      font-size: 1rem;
      padding: 1rem 2rem 1rem;
      background-color: #fff;
      color: $blue;
      font-weight: 500;
    }

    .p-1 {
      font-size: 1.3rem !important;
      color: #fff !important;
    }
  }
}

.faq-sec-b {
  padding: 50px 5% 100px 5%;

  .heading-3 {
    font-size: 2rem !important;
  }

  .p-1 {
    font-size: 1.2rem !important;
    width: 90%;

    @media (max-width: 960px) {
      width: 100%;
    }
  }
}

.faq-sec-c {
  // padding: 50px 5% 0px 5%;
  margin-bottom: 50px;

  .cl-1 {
    .p-1 {
      font-size: 1.2rem !important;
      margin-bottom: 20px;
    }

    .contactForms {
      .flexset {
        .flexsetinner {
          label {
            font-size: 1rem;
          }

          input {
            font-size: 1rem;
            height: unset;
            padding: 1rem;
            background-size: 15px !important;
            background-position: 95% 50% !important;
          }

          .react-tel-input {
            .form-control {
              padding-left: 3.5rem;
            }
          }
        }
      }

      .btncont {
        .btnform {
          width: fit-content;
          height: unset;
          font-size: 1.1rem;
          padding: 1rem 3rem 1rem;
        }
      }
    }
  }

  .cl-2 {
    justify-content: flex-end;

    @media (max-width: 992px) {
      justify-content: center;
    }

    img {
      width: 75%;
      max-width: 500px;
    }
  }
}

// ------------ Security Center Style ----------------
.pppagecontent {
  .cover-img {
    .Lpp {
      img {
        width: 60%;
        height: unset;
        max-width: 400px;

        @media (max-width: 900px) {
          width: 60%;
          height: unset;
          margin-top: 1rem;
        }
      }
    }

    .Rpp {
      row-gap: 0;

      h2 {
        font-size: 3rem;
        line-height: inherit;
      }

      p {
        font-size: 1.6rem;
        line-height: inherit;
        margin-bottom: 0;
        width: 70%;
      }
    }
  }

  .intro {
    font-size: 1rem;
    line-height: inherit;
  }

  .content {
    ul {
      li {
        .sectiontwo {
          .first {
            font-size: 1rem;
          }

          .second {
            font-size: 1rem;
          }

          .newest {
            font-size: 1rem;
          }
        }
      }
    }

    .mainstart {
      font-size: 1.3rem;
    }

    .contentSection {
      font-size: 1rem;
    }
  }

  .contact-email {
    .coup {
      font-size: 1rem;
    }

    .codown {
      font-size: 1rem;
    }
  }

  .admininfo {
    .admininfo1 {
      .positionE {
        font-size: 1.1rem;
      }

      .dis {
        ul {
          li {
            font-size: 1.1rem;
          }
        }
      }
    }

    .admininfo2 {
      .positionE {
        font-size: 1.1rem;
      }

      .dis {
        ul {
          li {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}

// ------------ Home Style ----------------
.IndexsBannerSection {
  background-image: linear-gradient(1deg, rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0)), url(../assets/images/hero-img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $blue;
  margin-bottom: 50px;
  height: 80vh;

  .IBSContainer {
    max-width: 1570px;

    .IBSLeft {
      max-width: 620px;
      padding: 100px 0;

      .heading-1 {
        font-size: 3rem !important;
        margin-bottom: 20px;
        color: #fff;
      }

      .heading-2 {
        font-size: 1.3rem !important;
        font-weight: 400;
        color: #fff !important;
      }

      .FormCard {
        .InputContent {
          input {
            font-size: 0.93rem;
          }
        }

        button {
          padding: 10px 15px;
          font-size: 1rem;
          font-weight: 400;
          height: unset;
        }
      }
    }

    .hero-card {
      background: #ffffffed;
      padding: 35px;
      max-width: 700px;

      .heading-1,
      .heading-2 {
        color: #000 !important;

      }
    }

    .IBSRight {
      img {
        max-width: 600px;
      }
    }
  }
}

.SectionTopHeader {
  margin-bottom: 0;

  .heading-3 {
    font-size: 2rem !important;
  }

  .SubHedding {
    font-size: 1.3rem !important;
    font-weight: 500;
  }
}

.CareerCad {
  img {
    margin-bottom: 1rem;
    height: 40px;
    width: 50px;
  }

  .body-1 {
    font-size: 1rem;
  }
}

.Number {
  font-size: 3rem;
  top: -71px;
  font-weight: bold;
  color: #A8A7B2 !important;
}

.WorksCard {
  width: 200px;
  height: 200px;
  border-radius: 65px;

  img {
    height: 50px;
    width: 50px;
  }
}

.Tag {
  font-size: 1.3rem;
  font-weight: 600;
  position: absolute;
  bottom: -60px;
  color: #A8A7B2;
}

.FeatureCad {
  padding: 30px 30px;

  .Title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .Text {
    font-size: 1rem;
  }
}

.ArrowDown {
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: 50px;

  a {
    transform: rotate(136deg);
    font-size: 2rem;
    text-decoration: none;
    background: #fff;
    box-shadow: 4px -5px 20px 0px rgb(0 0 0 / 5%);
    border-radius: 25%;
    padding: 6px 13px;

    &:active {
      background: #f3f5fb;
    }

    svg {
      transform: rotate(45deg);
    }

  }
}

.styles_scroll-to-top__2A70v {
  box-shadow: 0px -12px 25px 0 rgb(132 128 177 / 28%) !important;
  transform: rotate(136deg);

  &:active {
    transform: rotate(136deg) !important;
    background: #f3f5fb;
  }

  svg {
    transform: rotate(226deg);
  }
}

.styles_scroll-to-top__2A70v {
  box-shadow: 0px -12px 25px 0 rgb(132 128 177 / 28%) !important;
  transform: rotate(136deg);

  &:active {
    transform: rotate(136deg) !important;
  }

  svg {
    transform: rotate(226deg);
  }
}

// ------------ Features Style ----------------
.ftrs-sec-a {

  .lnk-text,
  a {
    font-size: 1rem !important;
    font-weight: 500;
  }

  .heading-1 {
    font-size: 2rem !important;
    margin-bottom: 15px;
    color: #fff;
  }

  .body-1 {
    font-size: 1rem !important;
    margin-bottom: 5px;
    color: #fff !important;
  }

  .img-a {
    img {
      width: 80%;
      max-width: 700px;
    }
  }

  .cnt-a {
    display: flex;
    justify-content: flex-start;
  }
}

.ftrs-sec-b {
  padding: 50px 5% 0;

  .cl-1 {
    margin-bottom: 0;
    justify-content: flex-start;

    img {
      max-width: 600px;
    }
  }

  .cl-2 {
    justify-content: flex-end;

    .heading-3 {
      font-size: 2rem !important;
    }

    .body-1 {
      font-size: 1rem !important;
    }

    .ftr-card {
      margin-bottom: 1rem;
    }
  }
}

.ftrs-sec-c {
  padding: 80px 5% 50px;

  .crd-rw {
    .body-1 {
      font-size: 1rem !important;
    }

    .ftr-card {
      width: 50%;
      margin-bottom: 1rem;

      .ftr-ico {
        width: 65px;
        height: 65px;
        background: #ffffff;
        box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 30px;
          height: auto;
        }
      }

      @media (max-width: 1200px) {
        width: 100%;
      }
    }

    .cntnt {
      width: 80%;
      margin-left: 25px;

      .ttl {
        color: #0C2679 !important;
      }
    }
  }
}

.ftrs-sec-d {
  padding: 50px 5% 0;

  .cl-1 {
    margin-bottom: 0;
    justify-content: center;

    img {
      max-width: 400px;
    }
  }

  .cl-2 {
    justify-content: flex-end;

    .heading-3 {
      font-size: 2rem !important;
    }

    .body-1 {
      font-size: 1rem !important;
    }

    .ftr-card {
      margin-bottom: 1rem;
    }
  }
}

.ftrs-sec-e {
  background-color: #f9f9fc;
  padding: 80px 5% 80px;

  .crd-rw {
    .body-1 {
      font-size: 1rem !important;
    }

    .ftr-card {
      width: 50%;
      margin-bottom: 1rem;

      .ftr-ico {
        width: 65px;
        height: 65px;
        background: #ffffff;
        box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 30px;
          height: auto;
        }
      }

      @media (max-width: 1200px) {
        width: 100%;
      }
    }

    .cntnt {
      width: 80%;
      margin-left: 25px;

      .ttl {
        color: #0C2679 !important;
      }
    }
  }
}

// ------------ Find Job Style ----------------
.FJFormCard {
  .InputContent {
    .FGroup {
      margin: 1rem;

      .selection {
        height: unset;

        .text {
          font-size: 1rem;
        }

        .icon {
          height: 40px;
        }
      }

      input {
        height: unset;
        font-size: 1rem;
      }

      .ui.selection.active.dropdown {
        border-radius: 0 !important;
      }
    }
  }

  button {
    height: 0;
    padding: 2rem 2rem;
    font-size: 1rem;
    font-weight: 500;
  }
}

.css-14el2xx-placeholder {
  color: #a8a7b2 !important;
}

.FJFilterContent {
  .Header {
    font-size: 1.2rem;

    i {
      font-size: 1.2rem;
    }
  }
}

.AccordItem {
  .Header-accord {
    .btn-accord {
      h5 {
        font-size: 1rem !important;
      }
    }
  }

  .CheckContainer2 {
    font-size: 1rem;
  }
}

.RadioContainer {
  font-size: 1rem;
  padding-top: 3px;
}

// ------------ Profile Style ----------------

.ProfileTopHeader {
  font-size: 1.6rem;
}

.ProfileContainer {
  .ProLeftMenu {
    .ProfileDetailsCard {
      .ProPicContent {
        width: 150px;
        height: 150px;

        .ProPicUploder{
          .button.Btn{
            width: 25px;
            height: 36px;
            position: relative;
            top: 0;
            left: 0;
            overflow: hidden;
            background: transparent;
            /* padding: 6px 23px; */
            /* background: #3f4257; */
            border-radius: 100px;
          }
        }

        .ProPic {
          width: 150px;
          height: 150px;
          border-radius: 150px;

          .image {
            width: 150px;
            height: 150px;
            border-radius: 150px;
          }
        }
      }

      .ProPicDetailsContent {
        margin-top: 20px;

        .Name {
          font-size: 1.5rem;
        }

        .Email {
          font-size: 1rem;
        }

        .Location {
          font-size: 1rem;
        }
      }
    }

    .ProRightContent {
      .Btn.DfBtn.DarkBlueBtn {
        height: unset;
        padding: 0.7rem 2rem;
        font-size: 1rem;
        margin: 0 0 0 auto;
        background: #0C2679;
      }

      .card-recruiterH {
        .Card {
          &:hover {
            background-color: #dde6ff;
            transition: background 0.3s ease;
          }
        }
      }

      .RightContent {
        padding-left: 50px;

        @media (max-width: 1280px) {
          padding-left: 0;
        }
      }
    }
  }
}

.ProRightContent {
  .Btn.DfBtn.DarkBlueBtn {
    height: unset;
    padding: 0.7rem 2rem;
    font-size: 1rem;
    // margin: 0 0 0 auto;
    background: #0c2679;
  }

  .card-recruiterH {
    .Card {
      &:hover {
        background-color: #dde6ff;
        transition: background 0.3s ease;
      }
    }
  }

  .RightContent {
    padding-left: 50px;

    @media (max-width: 1280px) {
      padding-left: 0;
    }
  }
}

.jobcard {
  .jobPostedCardInner1 {
    .jobPostion {
      font-size: 1.3rem;
    }
  }
}

.jobPostedCardInner2 {
  .Btn.DfBtn_M.DarkBlueBtn {
    padding: 5px 5px;
    font-size: 1rem;
    font-weight: 600;
    height: unset;
  }
}

.cvr-pic {
  img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.SkillsContainer {
  .SkillsContent {
    .SkillsCardContent {
      .SkillsCard {
        height: unset;
        font-size: 1rem;
        padding: 5px 20px;
      }
    }
  }
}

.table {
  thead {
    background-color: #0c2679;

    tr {
      th {
        padding: 0.8rem 0.8rem;
        color: #fff;
      }
    }
  }

  tbody {
    tr {
      vertical-align: middle;

      td {
        padding: 0.8rem 0.8rem;
      }
    }
  }
}

.UCInputGroupt {
  label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  input {
    height: unset;
    padding: 1rem;
  }

  select {
    height: unset;
    padding: 1rem;
  }

  button {
    height: unset;
    padding: 1rem;
    font-size: 1rem;
  }

  .react-tel-input {
    input {
      height: unset;
      padding: 1rem;
      padding-left: 3.5rem;
      background-size: 15px !important;
    }
  }

  .selection {
    height: unset;
    padding: 1rem;

    .text {
      height: unset !important;
      padding: 1rem 0 !important;
    }
  }

  .ui.selection.dropdown {
    line-height: unset;
  }

  .ui.search.dropdown>input.search {
    top: 33%;
  }

  .ui.fluid.dropdown>.dropdown.icon {
    height: unset !important;
    top: 1rem;
    line-height: 1.5;
  }
}

.InnerButtonContent {
  justify-content: start;

  button {
    height: unset;
    padding: 1rem;
    font-size: 1rem;
  }
}

a.ui.label {
  background-color: #253c87;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  transition: background 0.3s ease;
}

// .profile-select {
//     .ui.selection.dropdown {
//         line-height: unset;
//     }
//     .ui.search.dropdown>input.search {
//         top: 33%;
//     }
//     .ui.fluid.dropdown>.dropdown.icon {
//         height: unset !important;
//         top: 1rem;
//         line-height: 1.5;
//     }
// }
.createaj {
  .ui.selection.dropdown {
    line-height: unset;
  }

  .ui.search.dropdown>input.search {
    top: 33%;
  }

  .ui.fluid.dropdown>.dropdown.icon {
    height: unset !important;
    top: 1rem;
    line-height: 1.5;
  }
}

select {
  background: url("data:image/svg+xml,<svg width='9px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;

  &:focus-visible {
    outline: 1px solid #ffffff;
  }
}

.TabHeadersUsers {
  width: 100%;
  justify-content: inherit;

  a {
    font-size: 1.1rem;
    margin-bottom: 20px;
    width: inherit;
  }
}

.TabHeadersUsers.headtitle-more {
  justify-content: space-between;

  a {
    width: auto;
  }
}

.TabHeadersUsers.headtitle-more {
  justify-content: space-between;

  a {
    width: auto;
  }
}

.SkillsContainer {
  .SkillsContent {
    .SubHedding {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    form {
      input {
        height: unset;
        padding: 1rem;
      }
    }
  }
}

.GrayCard {
  .Header {
    .Right {
      .Button {
        img {
          width: 15px;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .Left {
      h4 {
        margin-left: 0;
      }
    }
  }

  .Header,
  .Body {
    padding-left: 30px;
    padding-right: 30px;
  }
}

// ------------ Recruiter Profile Style ----------------

.row_h {
  td {
    .Btn.DfBtn_S.DarkBlueBtnOutline {
      border-radius: 3px;
      padding: 0px 10px;
      height: unset;
      background: transparent;

      &:hover {
        background: transparent;
        padding: 0px 10px;
        height: unset;
      }
    }

    .Btn.DfBtn_S.DarkBlueBtnOutline.editBtn {
      border: #ffffff00 2px solid;
      color: #000000;
      background: #ff9f43;
      border-radius: 3px;
      padding: 0px 10px;
      height: unset;
    }

    .Btn.DfBtn_S.DarkBlueBtnOutline.yellowBtn {
      border: #ffffff00 2px solid;
      color: #fdfdfd;
      background: #FFB800;
      border-radius: 3px;
      padding: 0px 10px;
      height: unset;
      padding: 5px 10px 5px 10px;
    }

    .Btn.DfBtn_S.DarkBlueBtnOutline.greenBtn {
      border: #ffffff00 2px solid;
      color: #fdfdfd;
      background:#15C006;
      border-radius: 3px;
      padding: 0px 10px;
      height: unset;
      padding: 5px 10px 5px 10px;
    }

    .Btn.DfBtn_S.DarkBlueBtnOutline.blueBtn {
      border: #ffffff00 2px solid;
      color: #fdfdfd;
      background:#0C2679;
      border-radius: 3px;
      padding: 0px 10px;
      height: unset;
      padding: 5px 10px 5px 10px;
    }

    .Btn.DfBtn_S.DarkBlueBtnOutline.transparentBtn {
      border: #0C2679 2px solid;
      color: #0C2679;
      // background: #ff9f43;
      border-radius: 3px;
      padding: 0px 10px;
      height: unset;
      padding: 5px 10px 5px 10px;
    }

    .Btn.DfBtn_S.DarkBlueBtnOutline.noOutLineBtn {
      border: transparent;
      color: #0C2679;
      text-decoration: underline;
      text-underline-offset: 4px; /* Adjust this value to change the distance between the text and the underline */
      border-radius: 3px;
      padding: 0px 10px;
      height: unset;
      padding: 5px 10px 5px 10px;
    }

    /* Apply margin to all buttons except the last one */
    .Btn {
      margin-right: 10px; /* Adjust the value to increase or decrease the gap between buttons */
    }

    /* Remove margin from the last button */
    .Btn:last-child {
      margin-right: 0;
    }

    .Btn.DfBtn_S.RedBlueBtn {
      border: 2px solid #e86565;
      border-radius: 3px;
      padding: 0px 20px;
      height: unset;

      &:hover {
        background: #cf3f3f;
        color: #ffffff;
        border: 2px solid transparent;
        padding: 0px 20px;
        height: unset;
      }
    }

    // .Btn.DfBtn_S.DarkBlueBtnOutline.viewBtn{
    //     border: #0c2679 2px solid;
    //     color: #ffffff;
    //     background: #0c2679;
    //     border-radius: 3px;
    //     padding: 0px 10px;
    //     height: unset;
    // }

    &:hover {
      background-color: unset;
      color: unset;
    }

    a {
      text-decoration: none;
      font-weight: 600;
      color: #0c2679;

      &:hover {
        background-color: unset;
        color: unset;
      }
    }

    button {
      &:hover {
        background-color: unset;
        color: unset;
      }
    }
  }
}

a.Btn.DfBtn_S.DarkBlueBtnOutline {
  display: inline-block;
  border-radius: 4px;
  padding: 0px 10px;
  height: unset;
  background: transparent;
}

// ------------ Talent Pool Style ----------------
.TPFormCard {
  .InputContent {
    .FGroup {
      margin: 0;

      .selection {
        height: unset;

        .text {
          font-size: 1rem;
        }

        .icon {
          height: 40px;
        }
      }

      input {
        height: unset;
        font-size: 1rem;
      }

      .ui.selection.active.dropdown {
        border-radius: 0 !important;
      }
    }
  }

  button {
    height: 0;
    padding: 2rem 2rem;
    font-size: 1rem;
  }
}

.TPFilterContent {
  .Header {
    font-size: 1.2rem;

    i {
      font-size: 1.2rem;
    }
  }
}

// ------------ Talent Pool Style ----------------
.CandidateCard {
  .CCBody {
    .CCBodyRight {
      .CCBRtop {
        .NameContent {
          h3 {
            font-size: 1.3rem;
          }

          p {
            font-size: 1rem;
          }
        }

        .Salary {
          font-size: 1rem;
        }
      }
    }
  }

  .CCFooter {
    button {
      font-size: 1rem;
    }
  }
}

// ------------ Job Post Style ----------------
.ViewJobsSection {
  .ViewJobHeaderContainer {
    .ViewJobHeaderContent {
      .JobCompanyName {
        h2 {
          font-size: 1.7rem;
          margin-bottom: 0;
          font-weight: 700;
        }

        h4 {
          font-size: 1.2rem;
          margin-bottom: 0;
          font-weight: 700;
        }

        .JVSaveButton {
          padding: 5px 5px;
          font-size: 1rem;
          font-weight: 600;
          height: unset;
        }

        .JobTitle {
          font-size: 1.2rem;
          margin: 0 0 20px 0;
        }
      }
    }
  }

  .ViewJobBoddyContainer {
    .ViewJobBoddyContant {
      .JVRightContent {
        .JVRSection {
          h4 {
            font-size: 1.2rem;
          }

          .Text {
            font-size: 1rem;
            line-height: unset;
          }

          .JVRSectionContent {
            i {
              font-size: 1.5rem;
              margin-right: 10px !important;
              margin-top: 5px;
            }

            .TextContent {
              .Label {
                font-size: 1.1rem;
              }

              .Title {
                font-size: 1rem;
              }

              .Title2 {
                font-size: 1rem;
              }
            }
          }
        }

        .Text {
          font-size: 1rem;
          line-height: inherit;
        }
      }

      .JVLeftContent {
        .QuackDetailsCard {
          min-height: 90px;

          li {
            padding: 20px;

            .DetailsBox {
              justify-content: center;

              .Label {
                font-size: 1rem;
              }

              .Title {
                font-size: 1.2rem;
              }
            }
          }
        }

        .JobViewSection {
          margin: 0 0 20px 0;

          h4 {
            font-size: 1.2rem;
            margin: 0 0 10px 0;
          }

          .Text {
            font-size: 1rem;
          }

          .ListContent {
            margin-bottom: 20px;

            li {
              font-size: 1rem;
              padding: 0 0 0 25px;
              margin: 0;

              &:after {
                top: 9px;
              }
            }
          }

          .BtnContent {
            button {
              height: unset;
              padding: 1rem;
              font-size: 1rem;
              line-height: inherit;
            }

            a.Btn {
              height: unset;
              padding: 1rem;
              font-size: 1rem;
              line-height: inherit;
            }
          }

          // img{
          //     height: 400px;
          //     object-fit: cover;
          //     -o-object-fit: cover;
          // }
        }

        .JobTitle {
          font-size: 2rem;
          margin: 15px 0px 30px 0;
        }
      }
    }
  }
}

.UCRegCol50 {
  .UCInputGroupt {
    align-items: flex-start;
  }
}

.ui.modal>.actions {
  button {
    height: unset;
    padding: 1rem 2rem;
    font-size: 1rem;
    line-height: inherit;
  }
}

.JobCardContent {
  .Left {
    .TextContent {
      h3 {
        font-size: 1rem;
      }

      h4 {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: left;

        span {
          font-size: 1rem;
          margin: 0 10px;
          border: 1px solid #0C2679;
          padding: 5px 10px;
          border-radius: 10px;
          font-weight: normal;
        }
      }

      p {
        font-size: 1rem;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .TextContentInline {
        p {
          font-size: 1rem;
        }
      }
    }
  }

  .Right {
    span {
      font-size: 1rem;
    }

    a {
      height: unset;
      padding: 0.5rem;
      font-size: 1rem;
    }

    i {
      font-size: 1.5rem;
    }
  }
}

.ViewJobsSection {
  .JobOpportunitiesContainer {
    .JobOpportunitiesContant {
      .TextContent {
        h4 {
          font-size: 1.3rem;
          margin-bottom: 20px;
        }
      }
    }
  }
}
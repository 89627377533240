.LoginSection {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 105px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  flex-wrap: wrap;

  @media (max-width:1368px) {
    padding: 50px 0;
  }

  @media (max-width:1280px) {
    padding: 30px 0;
  }

  @media (max-width:1150px) {
    min-height: calc(100vh - 55px);
  }

  @media (max-width:600px) {
    min-height: 100vh;
  }


  .LoginContainer {
    width: 100%;
    max-width: 480px;
    height: auto;
    min-height: 400px;

    @media (max-width:1368px) {
        max-width: 400px;
      }

      @media (max-width:600px) {
        max-width: 300px;
      }

      @media (max-width:320px) {
        max-width: 260px;
      }

      .ui{
        width: 100%;
        border: 0;
        box-shadow: none;
    }
    

    .pointing {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 0;
        box-shadow: none;
        background: #E6E6E7;

        .item{
            flex:1;
            background: #E6E6E7;
            min-height: 60px;
            border-radius: 3px;
            color: #A8A7B2;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 400;

            @media (max-width:1440px) {
                min-height: 54px;
            }

            @media (max-width:1368px) {
                font-size: 16px;
                min-height: 50px;
            }

            @media (max-width:1024px) {
                font-size: 14px;
            }

            @media (max-width:600px) {
                min-height: 46px;
            }


            &:first-child{
                border-radius: 3px 0 0 3px;
            }

            &:last-child{
                border-radius: 0 3px 3px 0;
            }

            &::after{
                display: none;
            }

            &::before{
                display: none;
            }

            &:hover{
                background: #E6E6E7;
                color: #A8A7B2;
            }
        }

        .active{
            background: #0C2679;
            color: #fff;
            border-radius: 3px !important;

            &:hover{
                background: #0C2679;
                color: #fff;
            }
        }


    }


      

    h2 {
      width: 100%;
      color: $blue;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 30px 0;
      padding: 0;
      font-weight: 800;

      @media (max-width:1368px) {
        margin: 0 0 15px 0;
        font-size: 24px;
      }

      @media (max-width:600px) {
        color: $siteblack;
        font-size: 20px;
      }
    }

    p {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 30px 0;
      padding: 0;
      font-weight: 500;

      @media (max-width:1368px) {
        margin: 0 0 15px 0;
      }

      @media (max-width:320px) {
        text-align: center;
      }

    }

    .InputContent{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .InputGroup{
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            box-shadow: 10px 10px 17px rgba(0, 0, 0, 0.03);
            border: 1px solid #D5D5D5;
            background-color: $white;
            margin:0 0 30px 0;
            border-radius: 5px;

            @media (max-width:1368px) {
                margin:0 0 15px 0;
            }

            .Icon{
               width:80px;
               height: 80px;
               color:#A8A7B2;
               font-size: 40px;
               display: flex;
               align-items: center;
               justify-content: center;

               @media (max-width:1368px) {
                width:66px;
                height: 66px;
                font-size: 30px;
                }
            }

            .Inputs{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;

                label{
                    width: 100%;
                    height: auto;
                    color: $textgray;
                    font-size: 16px;
                    padding: 0;
                    margin:0;
                    font-weight: 500;

                    @media (max-width:1368px) {
                        font-size: 14px;
                    }
                }

                input{
                    width: 100%;
                    height: auto;
                    color: $siteblack;
                    border:0;
                    font-size:16px;
                    border-radius: 0;
                    margin:0;
                    padding: 0;

                    @media (max-width:1368px) {
                        font-size:14px;
                    }

                }

                input{
                    &:focus, &:active{
                        outline: none;
                        box-shadow: none;
                        border-color:transparent;
                    }
                }

                ::placeholder {
                    color: $siteblack;
                  }
                  
                  :-ms-input-placeholder {
                    color: $siteblack;
                  }
                  
                  ::-ms-input-placeholder {
                    color: $siteblack;
                  }

                  input:-internal-autofill-selected {
                    background-color: #fff !important;
                }
            }
        }

    }

    .RemebreSection{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin:0 0 30px 0;

        @media (max-width:1368px) {
            margin:0 0 15px 0;
        }


        .Forget{
            width: auto;
            color:$textgray;
            font-size: 16px;
            font-weight: 800;
            margin: 0;
            padding: 0;
            text-decoration: none;

            @media (max-width:1368px) {
                font-size: 14px;
            }
        }
    }

    button{
        width: 100%;
        height: 70px;
        margin: 0 0 30px 0;

        @media (max-width:1368px) {
            height: 54px;
            margin: 0 0 15px 0;
        }
    }

    .SocialLogin{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width:600px) {
            justify-content:space-between;
        }

        .LinkContent{
            width: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0 30px;
            text-decoration: none;

            @media (max-width:600px) {
                margin: 0;
            }

            i{
                width:60px;
                height: 60px;
                font-size: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0 5px 0;

                @media (max-width:1368px) {
                    width:40px;
                    height: 30px;
                    margin: 0 0 0 0;

                    img{
                       width: 50%;
                    }
                }
            }

            span{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                color: $textgray;
                font-weight: 800;

                @media (max-width:1368px) {
                    font-size: 14px;
                }
            }
        }
    }
    .RegisterLink{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $textgray;
        font-size: 16px;
        margin-top: 30px;
        font-weight: 800;

        @media (max-width:1368px) {
            font-size: 14px;
            margin-top: 15px;
        }

        a{
            color: $blue;
            margin: 0;
            padding: 0 0 0 5px;
            text-decoration:none;
        }
    }
  }
}




.RegisterSection {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 105px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
    flex-wrap: wrap;

    @media (max-width:1368px) {
        padding: 50px 0;
    }

    @media (max-width:1150px) {
        min-height: calc(100vh - 55px);
    }

    @media (max-width:600px) {
        padding: 30px 0;
    }
  
    .RegisterContainer {
      width: 100%;
      max-width: 470px;
      height: auto;
      min-height: 400px;

      @media (max-width:1280px) {
        max-width: 400px;
    }

    @media (max-width:600px) {
        max-width: 300px;
    }


    .ui{
        width: 100%;
        border: 0;
        box-shadow: none;
    }
    

    .pointing {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 0;
        box-shadow: none;
        background: #E6E6E7;

        .item{
            flex:1;
            background: #E6E6E7;
            min-height: 60px;
            border-radius: 3px;
            color: #A8A7B2;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 400;

            @media (max-width:1440px) {
                min-height: 54px;
            }

            @media (max-width:1368px) {
                font-size: 16px;
                min-height: 50px;
            }

            @media (max-width:1024px) {
                font-size: 14px;
            }

            @media (max-width:600px) {
                min-height: 46px;
            }


            &:first-child{
                border-radius: 3px 0 0 3px;
            }

            &:last-child{
                border-radius: 0 3px 3px 0;
            }

            &::after{
                display: none;
            }

            &::before{
                display: none;
            }

            &:hover{
                background: #E6E6E7;
                color: #A8A7B2;
            }
        }

        .active{
            background: #0C2679;
            color: #fff;
            border-radius: 3px !important;

            &:hover{
                background: #0C2679;
                color: #fff;
            }
        }


    }
    

    .segment{
        width: 100%;
        border: 0;
        box-shadow: none;
        padding: 0;
        margin-top: 40px;

        @media (max-width:1368px) {
            margin-top: 15px;
        }
    }
  
      h2 {
        width: 100%;
        color: $blue;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 30px 0;
        padding: 0;
        font-weight: 800;

        @media (max-width:1368px) {
            margin: 0 0 15px 0;
            font-size: 24px;
        }

        @media (max-width:1024px) {
            font-size: 20px;
        }

        @media (max-width:600px) {
            color: $siteblack;
        }
      }
  
      p {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 30px 0;
        padding: 0;
        font-weight: 500;
        font-size:18px;

        @media (max-width:1368px) {
            margin: 0 0 15px 0;
            font-size: 16px;
        }

        @media (max-width:1024px) {
            font-size: 14px;
        }

        @media (max-width:600px) {
            text-align: center;
        }
      }
  

      .RegRowContent {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
      }
      
      .RegRow {
        display: flex;
        flex-wrap: wrap;
        margin-left: -20px;
        margin-right: -20px;
      }
      
      .RegCol50 {
        flex: 50%;
        max-width: 50%;
        display: flex;
        padding-left: 20px;
        padding-right: 20px;

        @media (max-width:600px) {
            flex: 100%;
            max-width: 100%;
        }

      }  
      
      .RegCol100 {
        flex: 100%;
        max-width: 100%;
        display: flex;
        padding-left: 20px;
        padding-right: 20px;
      }   
  
  
      .RemebreSection{
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin:0 0 30px 0;
  
  
          .Forget{
              width: auto;
              color:$textgray;
              font-size: 16px;
              font-weight: 800;
              margin: 0;
              padding: 0;
              text-decoration: none;
          }
      }
  
      button{
          width: 100%;
          height: 70px;
          margin: 0 0 30px 0;

          @media (max-width:1440px) {
            height:60px;
          }

          @media (max-width:1368px) {
            height:54px;
            margin: 0 0 15px 0;
          }
      }
  
      .SocialLogin{
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 15px;
  
          .LinkContent{
              width: auto;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 0;
              margin: 0 30px;
              text-decoration: none;
  
              i{
                  width:60px;
                  height: 60px;
                  font-size: 60px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0 0 5px 0;
              }
  
              span{
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 16px;
                  color: $textgray;
                  font-weight: 800;
              }
          }
      }
      .RegisterLink{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $textgray;
          font-size: 16px;
          margin-top: 10px;
          font-weight: 800;

          @media (max-width:1368px) {
            font-size: 14px;
          }
  
          a{
              color: $blue;
              margin: 0;
              padding: 0 0 0 5px;
              text-decoration:none;
          }
      }
    }
  }


  .RegInputGroupt{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // justify-content: center;
    margin-bottom: 30px;

    @media (max-width:1368px) {
        margin-bottom: 15px;
      }

    label{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $textgray;
        font-size: 16px;
        font-weight: 500;

        @media (max-width:1368px) {
            font-size: 14px;
          }
    }

    input{
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-shadow: 10px 10px 17px rgba(0, 0, 0, 0.02);
        border-radius: 5px;
        border: 1px solid #D5D5D5;
        padding: 0 15px;

        @media (max-width:1440px) {
            height: 54px;
        }

        @media (max-width:1368px) {
            height: 50px;
        }

        @media (max-width:600px) {
            min-height: 46px;
        }

    }

    .text-danger{
        font-size: 12px;

        @media (max-width:1368px) {
           font-size: 10px;
        }
    }

}


.MobileBrandContent{
    width: 100%;
    height: auto;
    display: none;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    .MobileBrand{
        width: 200px;

        img{
            width: 100%;
        }
    }

    @media (max-width:600px) {
     display: flex;
    }

    
}
.social_btn{
    background-color: white;
    border: none;
    
    button{
        height: 24px;
    }
}

/*--------------------------------------- ABOUT US SCSS --------------------------- */
  .aboutus-sec-1{
    background-color: #F8F9FF;
    height: 350px;
    @media (max-width: 576px) { 
        height: 280px;
     }
    @media (max-width: 768px) { 
         height: 300px;
    }
    @media (max-width: 992px) { 
        height: 350px;
    }
        .about-sect-inner{
            margin-top: auto;
            margin-bottom: auto;
            .description-txt{
              margin-right: 3rem !important;
              margin-left: 3rem !important;
            }

        }
    }

    .section-outer3{
      background-image: url(../assets/images/aboutcover.png);
      background-repeat: no-repeat;
      background-size: cover;
      height: 600px;
      width: 100%;
      background-color: #F8F9FF;
      @media (max-width: 992px) { 
        height: 500px;
      }
      @media (max-width: 768px) { 
        height: 480px;
      }
      @media (max-width: 576px) { 
        height:470px;
      }
      @media (max-width: 480px) { 
        height: 400px;
      }
      @media (max-width: 400px) { 
        height: 420px;
      }
      
      .section-Inner3{
        width: 70%;
        margin-top: 10rem;
        margin-left: auto;
        margin-right: auto;
        background: #FFFFFF;
        border: 1px solid #ECEDF2;
        box-sizing: border-box;
        box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 15px;
        padding: 40px;
        @media (max-width: 992px) { 
          padding: 10px;
          margin-top: 6rem;
        }
        @media (max-width: 768px) { 
          margin-top: 6rem;
        }
        @media (max-width: 576px) {
            width: 85%; 
           margin-top: 4rem;
           padding: 10px;
         } 
         @media (max-width: 480px) { 
          margin-top: 4rem;
        }
        @media (max-width: 400px) { 
          margin-top: 3.2rem;
        }

        .heading-1{
          color: #243C86 !important;
        }
      }
    }

    .section-4-about{
      width: 55%;
      margin-top: 2rem;
      margin-bottom:8rem;
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
      margin-left: auto;
      margin-right: auto;
      background: #F8F9FF;
      border: 1px solid #ECEDF2;
      box-sizing: border-box;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 15px;
      padding: 40px;
      @media (max-width: 992px) { 
        padding: 10px;
      }
      @media (max-width: 576px) {
        width: 85%; 
       padding: 20px;
     }
    }

  .aboutMiddleLeft{
    @media (max-width:900px){
      width: 100%;
    }
    img{
      width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      @media (max-width:900px){
        width: 100%;
        margin-left: 2rem;
        margin-right: auto;
      }
    }
  }

  .section-4-aboutInner{
    img{
      width: 100%;
      height: auto;
    }
  }

  /*---------------------------- career Support ----------------------------------*/
  .section-1-careerSupport{
    background-color: #F8F9FF;
    padding-left: 0.5%;
    padding-right: 0.5%;
    .txt-container{
      @media (max-width: 992px) { 
        text-align: center !important;
      }
    }
    .img-container{
      img{
        width: 100%;
         height: auto;
      } 
    }
  }

  .section-2-careerSupport{
    padding: 50px 50px;
    margin-bottom: 100px;
    margin-top: 100px;

    @media (max-width:768px) {
      padding: 50px 20px;
      margin-bottom: 50px;
      margin-top: 50px;
    }

    .row{
      width: 90% !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  .blogitemcontainer{
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    // column-gap: 20px !important;
  }

  .blogitem{
    margin-bottom: 1.5rem !important;
    img{
      width: 100%;
      height: 70%;
      object-fit: cover;
      border-radius: 5px;
    }

    .blogiteminner1{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color:$textgray;
      font-weight: 500;
      margin-top: 20px;
      
      .nameAthour{
        font-size:18px;
        margin-right: 20px;
      }
      .positionAthour{
        font-size:15px;
      }
      .dateBlog{
        font-size: 14px;
        display: flex;
        align-items: center;
        row-gap: 10px;
      }
    }
  }
  

  .txtalign{
    text-align: right !important;

    @media(max-width:900px){
      text-align: left !important;
    }
  }

  .section-3-careerSupport{
    padding: 50px 50px;
    margin-bottom: 100px;

    @media (max-width:768px) {
      padding: 50px 20px;
      margin-bottom: 50px;
    }

    .row{
      width: 90% !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

/*------------------------------------------- talent pool -------------------------------------- */
.talent-pool-1{
  img{
    width: 100%;
    height: 100%;
  }
}


/*----------------------------------------- custom filter ----------------------------------------*/
.FGroup{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.widthmax-btn{
  width: 100% !important;
}

/*-----------------------------------------paymeny side content----------------------------------------*/
.paymentguide{
  background-color:$bluegray;
  padding: 50px;
  border-radius: 5px;
}
.paymentguide-headertxt{
  margin-bottom: 10px;
}
.paymentguide-leftcon{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.headingdiv{
  background-color: $blue;
  color: #fff;
  height: auto;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
}
/*-----------------------------------------carousel overide----------------------------------------*/
.carosel-nav{
  width: 100% !important;
  height: 80px !important;
}

.control-arrow {
  height: 80px !important;
}
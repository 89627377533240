html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  width: 100%;
  height: auto;
  min-height: 100%;
  position: relative;
  @media (max-width: 1366px) {
  }
  @media (max-width: 1000px) {
  }
}

input {
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    border-color: inherit;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $siteblack;
  transition: background-color 5000s ease-in-out 0s;
}

// p, h1, h2, h3, h4, h5, h6, a, span,
// div{
//   white-space: pre-wrap;
//   word-break: break-all;
// }

// Button

.Btn {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-decoration: none;
  opacity: 0.9;
  border-radius: 5px;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    opacity: 1;
  }
}

.DfBtn {
  height: 50px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 18px;
  font-weight: 600;

  @media (max-width:1440px) {
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
  }
}

.DfBtn_M {
  height: 30px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 14px;
  font-weight: 500;

  @media (max-width:900px) {
    height: 25px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
  }
}

.DfBtn_S{
  height: 24px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 14px;
  font-weight: 400;

}
.fix-width-8 {
  width: 8rem;
}
.fix-width-6{
  width: 6rem;
}

.DfBtnText {
  height: 50px;
  padding: 0;
  font-size: 18px;
  font-weight: 600;

  @media (max-width:1440px) {
    height: 40px;
    font-size: 14px;
  }
}

.NBDfBtnText {
  height: 50px;
  padding: 0;
  font-size: 18px;
  font-weight: 600;

  @media (max-width:1440px) {
    height: 40px;
    font-size: 14px;
  }

  @media (max-width:1150px) {
    height: 34px;
    font-size: 14px;
  }
}

.NBDfBtn {
  height: 50px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 18px;
  font-weight: 600;

  @media (max-width:1440px) {
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
  }

  @media (max-width:1150px) {
    height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }
}


.BlueBtn {
  background-color: $blue;
  color: $white;

  &:hover {
    color: $white;
  }
}

.BlueBtnOutline {
  border: $blue 2px solid;
  color: $blue;

  &:hover {
    color: $blue;
  }
}

.BlueBtnText {
  background-color: transparent;
  color: $blue;

  &:hover {
    color: $blue;
  }
}

.DarkBlueBtn {
  background-color: $darkblue;
  color: $white;

  &:hover {
    color: $white;
  }
}

.RedBlueBtn {
  background-color: #e55353;
  color: $white;

  &:hover {
    color: $white;
  }
}



.DarkBlueBtnOutline {
  border: $darkblue 2px solid;
  color: $darkblue;

  &:hover {
    color: $darkblue;
  }
}
.RedBtnOutline {
  border: #e55353 2px solid;
  color: #e55353;

  &:hover {
    color: #e55353;
  }
}


.DarkBlueBtnText {
  background: transparent;
  color: $darkblue;

  &:hover {
    color: $darkblue;
  }
}

.GrayBtn {
  background: #a8a7b2;
  color: $white;

  &:hover {
    color: $white;
  }
}

.OrangeBtn {
  background: #ff7a00;
  color: $white;

  &:hover {
    color: $white;
  }
}

//explore btn

.ExpoBtn {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-decoration: none;
  opacity: 0.9;
  color: $darkblue;
  font-size: 20px;
  font-weight: 400;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    opacity: 1;
  }

  @media (max-width:1440px) {
    font-size: 16px;
  }

  @media (max-width:1368px) {
    font-size: 14px;
  }

  @media (max-width:700px) {
    margin-top: 15px;
  }

  i {
    font-size: 24px;
    margin-left: 10px;

    @media (max-width:1440px) {
      font-size: 18px;
    }

    @media (max-width:1368px) {
      font-size: 16px;
      margin-left: 5px;
    }
  }
}

// Page Layout

.PageLayout {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 405px);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

// Page Section

.PageSection {
  // border: 1px solid green;
  width: 100%;
  height: auto;
  // min-height: calc(100vh - 105px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 50px 50px;
  margin-bottom: 100px;
  scroll-margin-top: 100px;

  @media (min-width:1921px) {
    // min-height: auto;
    // padding: 0px 50px;
  }

  @media (min-height:1080px) {
    // min-height: auto;
    // padding: 0px 50px;
  }

  
  @media (max-width:1680px) {
    // min-height: auto;
    // padding: 0px 50px;
  }

  @media (max-width:1280px) {
    // padding: 0 20px;
  }

  @media (max-width:1024px) {
    // padding: 0px 20px;
  }

  @media (max-width:768px) {
    padding: 50px 20px;
    margin-bottom: 50px;
  }

  .SectionContainer {
    width: 100%;
    max-width: 1570px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.BlueGrayBg {
  background-color: $bluegray;
}
.BlueBg {
  background-color: $blue;
}

.SectionTopHeader {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;

  @media (max-width:1440px) {
    margin-bottom: 40px;
  }

  @media (max-width:700px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .Hedding {
    width: auto;
    height: auto;
    font-size: 36px;
    font-weight: 700;
    color: $siteblack;
    margin: 0;
    padding: 0;

    @media (max-width:1440px) {
      font-size: 30px;
    }

    @media (max-width:1368px) {
      font-size: 24px;
    }
  }

  .SubHedding {
    width: auto;
    height: auto;
    font-size: 24px;
    font-weight: 700;
    color: $blue;
    margin: 0;
    padding: 0;

    @media (max-width:1440px) {
      font-size: 20px;
    }

    @media (max-width:1368px) {
      font-size: 18px;
    }

    @media (max-width:700px) {
      margin-top: 15px;
    }
  
  }
}

// Text

h1 {
  font-size: 60px;
  font-weight: 800;
}

h4 {
  font-size: 30px;
  font-weight: 800;
}

h5 {
  font-size: 24px;
  font-weight: 500;
}

/* checkbox */
.CheckContainer {
  display: flex;
  min-height: 30px;
  position: relative;
  padding: 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  user-select: none;
  align-items: center;
  justify-content: center;
  color: $textgray;

  @media (max-width:1368px) {
    font-size: 14px;
}

}

.CheckContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.CheckMark {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  margin: 0 10px 0 0;
}

.CheckContainer:hover input ~ .CheckMark {
  background-color: transparent;
}

.CheckContainer input:checked ~ .CheckMark {
  background-color: transparent;
}

.CheckMark:after {
  content: '';
  position: absolute;
  display: none;
}

.CheckContainer input:checked ~ .CheckMark:after {
  display: flex;
}

.CheckContainer .CheckMark:after {
  align-items: center;
  justify-content: center;
  width: 8px;
  height: 15px;
  bottom: 10px;
  border: solid $blue;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* checkbox2 */
.CheckContainer2 {
  display: flex;
  min-height: 25px;
  position: relative;
  padding: 0 0 0 45px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  user-select: none;
  align-items: center;
  justify-content: flex-start;
  color: $textgray;
}

.CheckContainer2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.CheckMark2 {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  border: 2px solid #dedfe3;
  border-radius: 3px;
  margin: 0 10px 0 0;
  background-color: #fff;
  position: absolute;
}

.CheckContainer2:hover input ~ .CheckMark2 {
  background-color: #fff;
}

.CheckContainer2 input:checked ~ .CheckMark2 {
  background-color: #fff;
}

.CheckMark2:after {
  content: '';
  position: relative;
  display: none;
}

.CheckContainer2 input:checked ~ .CheckMark2:after {
  display: flex;
}

.CheckContainer2 .CheckMark2:after {
  align-items: center;
  justify-content: center;
  width: 8px;
  height: 15px;
  bottom: 2px;
  border: solid $blue;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/*Radio Button */

.RadioContainer {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  color: $textgray;

  @media (max-width:1368px) {
    font-size: 14px;
  }
}

.RadioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.RadioCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #dedfe3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RadioContainer:hover input ~ .RadioCheckmark {
  background-color: #fff;
}

.RadioContainer input:checked ~ .RadioCheckmark {
  background-color: #fff;
}

.RadioCheckmark:after {
  content: '';
  position: absolute;
  display: none;
}

.RadioContainer input:checked ~ .RadioCheckmark:after {
  display: block;
}

.RadioContainer .RadioCheckmark:after {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: $blue;
}

.InnerPageSection {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 105px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;

  .PageTopLine {
    height: 90px;
    width: 100%;
    position: absolute;
    display: flex;
    background-color: $blue;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.UCRowContent {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.UCRegRow {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

.UCRegRow1 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

h5 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  font-size: 20px;
  color: $siteblack;
  margin: 20px;
  font-weight: 700;

  @media (max-width: 990px) {
    font-size: 16px;
  }
}

.UCRegCol33 {
  flex: 33.33%;
  max-width: 33.33%;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

.UCRegCol40 {
  flex: 40%;
  max-width: 40%;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    flex: 100%;
    max-width: 100%;
  }
}

.UCRegCol50 {
  flex: 50%;
  max-width: 50%;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    flex: 100%;
    max-width: 100%;
  }
}

.UCRegCol25 {
  flex: 25%;
  max-width: 25%;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    flex: 50%;
    max-width: 50%;
  }
}

.UCRegCol505 {
  flex: 50%;
  max-width: 50%;
  display: flex;
  padding-left: 50px;
  padding-right: 10px;
  margin-bottom:30px ;

  @media (max-width: 768px) {
    flex: 100%;
    max-width: 100%;
  }
}
.UCRegCol505_B{
  flex: 50%;
  max-width: 50%;
  display: flex;
  column-gap: 30px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom:20px ;

  @media (max-width: 768px) {
    flex: 100%;
    max-width: 100%;
  }
}
.img_L {
  flex: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 10px;
  margin-bottom:30px ;

  @media (max-width: 768px) {
    flex: 100%;
    max-width: 100%;
  }
}
.UCRegCol60 {
  flex: 60%;
  max-width: 60%;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    flex: 100%;
    max-width: 100%;
  }
}

.DirectionColum {
  flex-direction: column;
}

.JustifyStart {
  justify-content: flex-start !important;
}

.UCRegCol100 {
  flex: 100%;
  max-width: 100%;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

.UCInputGroupt {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 30px;

  label {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #777589;
    font-size: 16px;
    font-weight: 500;

    @media (max-width: 990px) {
      font-size: 14px;
    }
  }

  P {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $siteblack;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  input {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 10px 10px 17px rgb(0 0 0 / 2%);
    border-radius: 5px;
    border: 1px solid #d5d5d5;
    padding: 0 15px;

    @media (max-width: 1368px) {
      height: 50px;
    }
  }

  textarea {
    width: 100%;
    height: auto;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 10px 10px 17px rgb(0 0 0 / 2%);
    border-radius: 5px;
    border: 1px solid #d5d5d5;
    padding: 10px 15px 15px 15px;
  }

  select {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 10px 10px 17px rgb(0 0 0 / 2%);
    border-radius: 5px;
    border: 1px solid #d5d5d5;
    padding: 0 15px;

    @media (max-width:1440px) {
      height:50px;
    }
  }

  .react-tel-input {
    input {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-shadow: 10px 10px 17px rgb(0 0 0 / 2%);
      border-radius: 5px;
      border: 1px solid #d5d5d5;
      padding: 0 15px 0 60px;

      @media (max-width:1440px) {
        height:50px;
      }
    }

    .flag-dropdown {
      width: 45px;

      .selected-flag {
        margin: 0;
      }
    }
  }

  .selection {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 15px !important;

    @media (max-width:1440px) {
      height:50px;
    }

    &:hover {
      border: 1px solid #d5d5d5 !important;
    }

    .search {
      padding: 0 15px !important;
    }

    .text {
      height: 60px !important;

      @media (max-width:1440px){
        height: 50px !important;
      }
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 !important;
    }

    i {
      height: 60px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .menu {
      .text {
        height: 30px !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 !important;
      }
    }
  }

  .selection.active.dropdown {
    border-color: #d5d5d5 !important;
  }

  .selection.active.dropdown .menu {
    border-color: #d5d5d5 !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  form {
    width: 100%;

    .InputGroup {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .TextInput {
        flex: 1;
        border-radius: 5px 0 0 5px;
        border-right: 0;
      }

      .ButtonInput {
        width: 100px;
        border-radius: 0 5px 5px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        color: $textgray;
      }
    }
  }

  .TagContent {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .DataTag {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      // flex-wrap: wrap;
      // height: 42px;
      width: auto;
      padding: 15px 15px;
      color: $darkblue;
      font-weight: 500;
      font-size: 16px;
      background: #e8edfc;
      border-radius: 3px;
      margin: 15px 15px 0 0;

      button {
        background: transparent;
        border: 0;
        padding: 0;
        margin: 0 0 0 15px;
        color: $darkblue;
        font-size: 12px;
      }
    }
  }

  .SwitchContent {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    label {
      width: auto;
      margin-right: 20px;
    }

    .react-switch-bg {
      background: $darkblue !important;
    }
  }
}

.TabHeaders {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;

  @media (max-width: 990px) {
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  a {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 20px;
    color: $textgray;
    margin: 0 40px 30px 0;
    padding: 0 !important;
    text-decoration: none;

    @media (max-width: 1440px) {
      font-size: 18px;
    }

    @media (max-width: 1440px) {
      font-size: 16px;
      margin: 0 30px 20px 0;
    }

    @media (max-width:990px) {
      font-size: 14px;
      margin: 0 15px 5px 0;
    }

    &:hover {
      color: $blue;
    }

    &:last-child{
      margin-bottom: 20px;
    }
  }

  .active {
    color: $blue;
  }
}

.TabHeadersUsers {
  //swidth: 100%;
  height: auto;
  display: flex;
  align-items: center;
  // justify-content:space-between;
  margin: 0;
  padding: 0;

  
  @media (max-width: 1550px) {
    justify-content: space-between;
  }

  @media (max-width: 990px) {
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  @media (max-width: 600px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 990px) {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }

  a {
    width: auto;
    display: flex;
    align-items: center;
    // justify-content: center;
    font-weight: 500;
    font-size: 18px;
    color: $textgray;
    margin: 0 20px 40px 0;
    // padding: 0 !important;
    text-decoration: none;

    @media (max-width: 1550px) {
      margin: 0 0px 40px 0;
    }

    @media (max-width: 1440px) {
      font-size: 18px;
    }

    @media (max-width: 1440px) {
      font-size: 16px;
      margin: 0 0px 30px 0;
    }

    @media (max-width:1100px) {
      font-size: 14px;
      margin: 0 0px 20px 0;
    }
    @media (max-width:990px) {
      font-size: 14px;
      margin: 0 10px 20px 0;
    }

    &:hover {
      color: $blue;
    }

    // &:last-child{
    //   margin-bottom: 20px;
    // }
  }

  .active {
    color: $blue;
  }
}

.InnerButtonContent {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.IBCmb40 {
  margin-bottom: 40px;
}

.InnerFileUploader {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  flex-direction: column;

  .Image {
    width: 200px;
    height: 200px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    overflow: hidden;
    border: 1px solid #d5d5d5;
    box-shadow: 10px 10px 17px rgba(0, 0, 0, 0.02);
    border-radius: 5px 5px 0 0;
    padding: 10px;
    margin-top: 10px;

    img {
      width: 100%;
      border-radius: 5px;
    }
  }

  .fileUploader {
    width: 200px;
    .fileContainer {
      padding: 0;
      margin: 0;

      .chooseFileButton {
        margin: 0;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ebebeb;
        border-radius: 0 0 5px 5px;
        color: $textgray;
        border: 1px solid #d5d5d5;
        border-top: 0;
      }
    }
  }
}

.CommenfileUploader {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  box-shadow: 10px 10px 17px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  overflow: hidden;

  .fileUploader {
    .fileContainer {
      min-height: 150px;
      margin: 0;
      position: relative;
      padding: 0 0 50px 0;

      p {
        margin: 0 0 0 0;
        position: absolute;
      }

      .chooseFileButton {
        background: transparent;
        color: $textgray;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 50px;
        border: 0;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0 0 0;
        margin: 0;
        font-weight: 700;
        border-top: 1px solid #d5d5d5;
        background: #ebebeb;
        box-shadow: none;
        outline: none;
      }

      .uploadPicturesWrapper {
        position: relative;
        left: 0;
        right: 0;
        z-index: 4;
        width: 100%;
        height: 100%;

        div {
          .uploadPictureContainer {
            width: 100%;
            margin: 0;
            padding: 0;
            box-shadow: none;
            border: 0;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 40%;
              position: relative;
              z-index: 2;
            }

            .deleteImage {
              top: unset;
              right: unset;
              color: transparent;
              background: transparent;
              height: 40px;
              width: 100px;
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 3;

              &::after {
                content: 'Delete';
                background: #ff4081;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                width: 60px;
                color: #fff;
                font-size: 14px;
                font-weight: 700;
                border-radius: 3px;
              }
            }
          }
        }
      }
    }
  }
}

.CompanyImgContent {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  .ImgContent {
    width: 100%;
    height: auto;
    display: flex;
    min-height: 248px;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #d5d5d5;
    box-shadow: 10px 10px 17px rgba(0, 0, 0, 0.02);
    border-radius: 5px 5px 0 0;

    @media (max-width:1440px) {
      min-height: 218px;
    }

    img {
      width: 180px;
    }
  }

  .fileUploader {
    .fileContainer {
      margin: 0;
      padding: 0;

      p {
        display: none;
      }

      .chooseFileButton {
        margin: 0;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ebebeb;
        border-radius: 0 0 5px 5px;
        color: #777589;
        border: 1px solid #d5d5d5;
        border-top: 0;
      }
    }
  }
}

.GrayCard {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 5px;
  // box-shadow: 10px 10px 17px rgb(0 0 0 / 2%);
  margin: 0 0 30px 0;
  border: 1px solid #d5d5d5;

  .Header {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
    margin: 0 0 0 0;
    // border-bottom: 1px solid #d5d5d5;

    .Left {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .Image {
        width: 50px;
        height: 50px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;

        img {
          width: 80%;
          border-radius: 2px;
        }
      }

      h4 {
        color: $siteblack;
        font-size: 18px;
        font-weight: 700;
        margin-left: 15px;
      }
    }

    .Right {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .Button {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        border: 0 solid #ecedf2;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: $textgray;
        background-color: transparent;
        opacity: 0.9;
        margin: 0 0 0 15px;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .Body {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding:10px 15px 10px 75px;
    margin: 0;

    .Row {
      width: 100%;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 30px;
      flex-wrap: wrap;

      .ColMgR80{
        margin-right: 80px;
      }

      .ColMgR40{
        margin-right: 40px;
      }

      .ColPdR80{
        padding-right: 80px;
      }

      .Col33 {
        width: 33.33%;
        margin-bottom: 30px;
      }


      .Col {
        width: auto;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        label {
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #777589;
          font-size: 14px;
          font-weight: 500;
        }

        P {
          width: 100%;
          height: auto;
          display: contents;
          align-items: center;
          justify-content: flex-start;
          color: #1d1b31;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
          padding: 0;
        }

        .Discrip{
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: $textgray;
          font-size: 14px;
          font-weight: 500;
          margin: 0;
          padding: 0;
        }

        .DateContent{
          width: auto;
           display: flex;
           align-items: flex-start;
           justify-content: center;
           flex-wrap: wrap;
           margin-right: 40px;

           i{
             color: $siteblack;
             font-size: 18px;
             margin: 5px 10px 0 0;
           }

           .TextContent{
             width: auto;
             display: flex;
             align-items: center;
             justify-content: flex-start;
             flex-direction: column;

             .Lable{
               color: $textgray;
               font-size: 14px;
               font-weight: 600;
               margin: 0 0 0 0;
               padding: 0;
             }
 
             .Date{
               color: $siteblack;
               font-size: 16px;
               font-weight: 500;
               margin: 0 0 0 0;
               padding: 0;
             }
           }
         }
      }
    }
  }
}

.CandidateCard{
  width: 100%;
  height: auto;
  min-height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #ECEDF2;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 0 0 45px 0;
  position: relative;
  margin: 0 0 30px 0;

  .CCBody{
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 15px;
    margin: 0;

    .CCBodyLeft{
      width: 100px;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 0 20px 0 0;
      position: relative;

      .CCProImge{
        width: 100px;
        height: 100px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background: #F8F9FF;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.11);
        position: relative;

        img{
          width: 100%;
        }
      }

      .Avelable{
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background-color: #00D208;
        position: absolute;
        right: 15px;
        bottom: 0;
        z-index: 3;
      }
    }

    .CCBodyRight{
      flex:1;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;

      .CCBRtop{
        width: 100%;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 10px 0 0 0;
        margin: 0 0 15px 0;

        .NameContent{
          width: auto;
          display: flex;
          align-items: flex-start;
          justify-content:flex-start ;
          flex-direction: column;

          h3{
            color: $siteblack;
            font-weight: 500;
            font-size: 18px;
            padding: 0;
            margin: 0;
          }

          p{
            color: $textgray;
            font-weight: 500;
            font-size: 16px;
            padding: 0;
            margin: 0;
          }
        }

        .Salary{
            color: $textgray;
            font-weight: 500;
            font-size: 16px;
            padding: 0;
            margin: 0;
        }

      }

      .CCBRbottom{
        width: 100%;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding:0 0 0 0;

        .BCard{
          width: auto;
          background: #E6E6E7;
          border-radius: 3px;
          color: $textgray;
          font-size: 12px;
          padding: 5px 10px;
          margin: 0 10px 10px 0;

        }
      }
    }
  }
  .CCFooter{
    width: 100%;
    height: auto;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    bottom: 0;
    border-top: 1px solid #ECEDF2;
    padding: 0 15px;

    button{
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      border:0;
      background: transparent;
      opacity: 0.9;
      color: $blue;
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 0 15px;
      text-decoration: none;

      &:hover{
        opacity: 1;
        text-decoration: none;
      }
    }
  }

}

.carousel-root{
  width: 100%;

  .carousel{
    position: relative;
   // padding-bottom: 50px;

    .control-dots{
      margin: 0;

      .dot{
        width: 18px;
        height: 10px;
        background: #CEDAFF;
        border-radius: 20px;
        box-shadow: none;
      }

      .selected{
        width: 32px;
        background: #0C2679;
      }
    }
  }
}
.paymentPriceText{
   //margin-bottom: 10px;
   font-size: 1.75rem;
   padding: 12px;
}
.roleRedio{
  display: flex;
  align-items: center;
}
.file_name{
  font-size: 16px;
  margin-top: 10px;
  color: #0C2679;
}
.table-heder{
background-color: #a2a2a3;
}
.table-heder .table-centered{
  text-align: center;
}

.table-no-data{
  color: #777589;
  font-weight: 600;
}

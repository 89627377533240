/*----------------- General Fonts ----------------- */
.heading-1 {
    font-size: calc(32px + (48 - 32) * ((100vw - 300px) / (1600 - 300))) !important;
    font-weight: 600 !important;
  }

  .heading-1-talent {
    font-size: calc(20px + (36 - 20) * ((100vw - 300px) / (1600 - 300))) !important;
    font-weight: 500 !important;
  }
  
  .heading-2 {
    font-size: calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300))) !important;
    color: $textgray !important;
  }
  
  .heading-3 {
    font-size: calc(20px + (30 - 20) * ((100vw - 300px) / (1600 - 300))) !important;
    font-weight: 700 !important;
  }

  .heading-4{
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300))) !important;
    font-weight: 600 !important;
  }
  
  .body-1 {
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300))) !important;
    color: $textgray !important;
    //text-transform: capitalize !important;
    font-weight: 500 !important;
  }

  .features-typo-color{
    color: #0C2679 ;
  }

  .heading-Features {
    font-size: calc(20px + (40 - 20) * ((100vw - 300px) / (1600 - 300))) !important;
    font-weight: 700 !important;
  }
/*----------------------------------- ABOUT US TYPO ---------------------------------------*/

// .description-txt{
//     font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
//     font-weight: 500;
//     color: #777589;
//     text-transform: capitalize;
//     line-height: 30px;
//     @media (max-width: 576px) { 
//         line-height: 26px;
//     }
// }

/*----------------------------------- CAREER SUPPORT TYPO ---------------------------------------*/

.hedding_Career{
    font-size: calc(24px + (48 - 24) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
}

.description-txt_Career{

}
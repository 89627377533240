// Variables

$primary: #0C2679;

// Color system


$white:  #ffffff;
$blue: #0C2679;
$darkblue:#0C2679;
$bluegray:#F8F9FF;
$siteblack:#1D1B31;
$black: #000000;
$textgray:#777589;
$inputgray:#A8A7B2



